import React from 'react';
import { PostAdd, ArrowForward, Money } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IntlMessages from '../../../utils/IntlMessages';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AppsIcon from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

const planningMenus = {
  name: <IntlMessages id={'sidebar.main.planning'} />,
  icon: <ListAltIcon />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'sidebar.main.planning.taskmanagement'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/planning/taskmanagement-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.planning.manageteam'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/planning/manage-teams',
    },
    {
      name: <IntlMessages id={'sidebar.main.planning.projectsection'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/planning/project-page',
    },
  ],
};

const embeddedAnalyticsMenus = {
  name: 'Embedded Analytics',
  icon: <BarChartOutlinedIcon />,
  type: 'collapse',
  children: [
    {
      name: 'Add/Manage Reports',
      icon: <ArrowForward />,
      type: 'item',
      link: '/embedded-analytics/view-reports',
    },
    {
      name: 'Manage Teams',
      icon: <ArrowForward />,
      type: 'item',
      link: '/embedded-analytics/manage-teams',
    },
  ],
};

const crmMenus = {
  name: <IntlMessages id={'sidebar.main.crm'} />,
  icon: <SupervisorAccountIcon />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'sidebar.main.crm.contactsOverview'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/crm/contactsOverview-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.crm.crmManageSalesTeams'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/crm/teams',
    },

    {
      name: <IntlMessages id={'sidebar.main.crm.emailTemplates'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/crm/emailTemplates-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.crm.sendEmail'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/crm/sendEmail',
    },
  ],
};
// const expensesMenus = {
//   name: <IntlMessages id={'sidebar.main.expenses'} />,
//   icon: <Money />,
//   type: 'collapse',
//   children: [
//     {
//       name: <IntlMessages id={'sidebar.main.expenses.all'} />,
//       icon: <ArrowForward />,
//       type: 'item',
//       link: '/expenses/all',
//     },
//   ],
// };

const microsoftAuthMenus = {
  name: <IntlMessages id={'sidebar.main.microsoft'} />,
  icon: <AppsIcon />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'sidebar.main.microsoft.microsoftAuthentication'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/microsoft/authentication',
    },
  ],
};

const plaidAuthMenus = {
  name: <IntlMessages id={'sidebar.main.bankStatement'} />,
  icon: <AccountBalanceIcon />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'sidebar.main.bankStatement.overview'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/bank-statement/overview',
    },
    // {
    //   name: 'plaid test',
    //   icon: <ArrowForward />,
    //   type: 'item',
    //   link: '/plaid/test',
    // }
  ],
};

const accoutingMenus = {
  name: <IntlMessages id={'sidebar.main.accounting'} />,
  icon: <AccountBalanceIcon />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'sidebar.main.accounting.invoice'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/invoice-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.accounting.teams'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/accounting-teams',
    },
    {
      name: <IntlMessages id={'sidebar.main.accounting.companyProfile'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/company-profile',
    },
    {
      name: <IntlMessages id={'sidebar.main.accounting.clients'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/clients-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.accounting.bankAccount'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/bankAccount-page',
    },

    {
      name: <IntlMessages id={'sidebar.main.accounting.quotation'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/quotation-page',
    },
    {
      name: <IntlMessages id={'sidebar.main.accounting.CreditNote'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/accounting/credit-note',
    },
    {
      name: <IntlMessages id={'sidebar.main.expenses.all'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/expenses/all',
    },
    {
      name: <IntlMessages id={'sidebar.main.bankStatement.overview'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/bank-statement/overview',
    },
  ],
};

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <DashboardIcon />,
        link: '/dashboard-page',
      },
      accoutingMenus,
      planningMenus,
      crmMenus,
      embeddedAnalyticsMenus,
      microsoftAuthMenus,
      // plaidAuthMenus,
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/dashboard-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.dashboard'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/dashboard-page',
      },
    ],
  },
];

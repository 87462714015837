import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import Delete from '@material-ui/icons/Delete';
import { Stack } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { SaveOutlined } from '@material-ui/icons';
const NoteItem = ({ value, setValue, editNote, deleteNote, noteItem }) => {
  const [noteValue, setNoteValue] = useState('');
  const [noteDateTime, setNoteDateTime] = useState({
    date: '',
    time: '',
  });

  const styles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 50,
    },
    textField: {
      width: 300,
      color: 'black',
      fontSize: 30,
      opacity: 1,
      borderBottom: 0,
      '&:before': {
        borderBottom: 0,
      },
    },
    disabled: {
      color: 'black',
      borderBottom: 0,
      '&:before': {
        borderBottom: 0,
      },
    },
    btnIcons: {
      marginLeft: 10,
    },
  }));
  const classes = styles();

  useEffect(() => {
    console.log();

    setNoteValue(noteItem.CrmNotes);
    let currDate = noteItem.CrmNoteDate;
    if (currDate) {
      let locaDateStr = new Date(currDate).toLocaleString('en-uk').split(',');
      let dateTimeObj = {
        date: locaDateStr[0],
        time: locaDateStr[1],
      };
      setNoteDateTime(dateTimeObj);
    }

    // console.log(noteItem.CrmNotes);
  }, [value]);

  const saveNote = (args, currValue) => {
    if (currValue.trim() == args.CrmNotes.trim()) return;
    let dataObj = {
      ContactId: args.crmContactId,
      EditedNote: noteValue,
      NoteId: args.Id,
      NoteDate: new Date().toISOString(),
    };
    editNote(dataObj);
  };

  // debounce start
  let timer = 100,
    timeout;
  const debounce = func => {
    clearTimeout(timeout);
    timeout = setTimeout(func, timer);
  };
  // debounce end
  const updateNotesText = args => {
    setNoteValue(args);
  };

  const textFieldStyles = makeStyles(theme => ({
    root: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
    },
    stack: {
      fontFamily: 'Normal',
    },
  }));
  return (
    <div style={{ marginTop: '20px' }}>
      <div className="w-100 border" style={{ borderRadius: '5px', borderColor: 'rgba(0,0,0,0.450)' }}>
        <div>
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} ml={2}>
            <IconButton size="small" onClick={() => deleteNote(noteItem)}>
              <Delete fontSize="small" />
            </IconButton>
            <Stack
              direction="row"
              fullwidth="true"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pl={5}
              fontSize={15}
              className={textFieldStyles().stack}>
              <span>{noteDateTime.date}</span>
              <span>{noteDateTime.time}</span>
            </Stack>
          </Stack>
        </div>

        <div
          className="word-wrap text-break px-3 pt-2 "
          style={{ minHeight: '50px', maxHeight: '400px', background: 'whiteSmoke' }}>
          {/* <TextField name="email"
          defaultValue={value}
          margin="normal"
          
          // onChange={handleChange}
          disabled={!this.state.editMode}
          className={classes.textField}> */}

          <TextField
            name="note"
            value={noteValue}
            fullWidth
            multiline
            className={textFieldStyles().root}
            minRows={3}
            maxRows={12}
            onChange={e => updateNotesText(e.target.value)}
            onBlur={e => saveNote(noteItem, e.target.value)}></TextField>
        </div>
      </div>
    </div>
  );
};

export default NoteItem;

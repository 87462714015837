import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel } from '@mui/material';
import Select from 'react-select';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { type } from '@amcharts/amcharts4/core';
import { makeStyles } from '@material-ui/core';

const copyModalStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    gap: '3rem',
    marginBlock: '15px 20px',
    '& .checkboxCtn': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5px',
      gap: '1.5rem',
      '& label': {
        marginBottom: 0,
        fontSize: '1rem',
      },
      '& input': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CopyMoveContact = props => {
  const { handleClose, contactObj, teams, selectedTeam, stages, updateCRMContactList } = props;
  const [allTeams, setAllTeams] = useState([]);
  const [allStage, setAllStage] = useState([]);
  const [copyIp, setCopyIp] = useState({ NotesFlag: 0, EmailCommFlag: 0, ContactAttachFlag: 0 });
  const [cmTeam, setCMTeam] = useState(null);
  const [cmStage, setCMStage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (contactObj.open) {
      console.log(props);
      let currTeams = teams.filter(item => item.value != selectedTeam.value);
      setAllTeams(currTeams);
      if (currTeams.length > 0) {
        updateStageDropDown(currTeams[0].value);
      }
    }
  }, [contactObj.open]);

  const updateStageDropDown = args => {
    if (!args) return;
    setCMTeam(args.value);
    let currStage = [];
    stages.map(item => {
      if (item.CrmTeamId === args.value) {
        currStage.push({ value: item.StageId, label: item.crmStageName });
      }
    });
    // console.log(currStage);
    setAllStage(currStage);
  };
  const selectStage = args => {
    setCMStage(args.value);
  };
  const applySelectedSelection = e => {
    e.preventDefault();

    try {
      if (!cmTeam) {
        dispatch({ type: FETCH_ERROR, payload: 'Please select a team' });
        return;
      }
      if (!cmStage) {
        // console.log('enter');
        dispatch({ type: FETCH_ERROR, payload: 'Please select a stage' });
        return;
      }

      let obj = {};
      let apiUrl = '';
      if (contactObj.label == 'Move') {
        apiUrl = '/CRM/MoveContact';
        obj = {
          CrmId: contactObj.itemObj.crmId,
          NewTeamId: cmTeam,
          NewStageId: cmStage,
        };
      } else {
        apiUrl = '/CRM/CopyContact';
        obj = {
          OldCrmId: contactObj.itemObj.crmId,
          NewCrmTeamId: cmTeam,
          NewCrmStageId: cmStage,
          NotesFlag: copyIp.NotesFlag,
          EmailCommFlag: copyIp.EmailCommFlag,
          ContactAttachFlag: copyIp.ContactAttachFlag,
        };
      }

      dispatch({ type: FETCH_START });

      customAxios
        .post(apiUrl, obj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (contactObj.label == 'Move') {
            updateCRMContactList(obj.CrmId);
          }
          handleClose();

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        });
    } catch (ex) {
      console.log(ex);
    }
  };
  const handelCBChange = args => {
    // console.log(args.target.name,args.target.checked);
    let oldState = copyIp;
    oldState[args.target.name] = args.target.checked ? 1 : 0;
  };
  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={contactObj.open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`${contactObj.label} Contact`}
        </BootstrapDialogTitle>
        <form onSubmit={applySelectedSelection}>
          <DialogContent dividers>
            <div style={contactObj?.label == 'Move' ? { display: 'none' } : {}}>
              <p style={{ fontWeight: 600, fontSize: '1.01rem' }}>
                Please select what you would like to copy from the selected contact.
              </p>
              <div className={copyModalStyles().root}>
                <div className="checkboxCtn">
                  <label>Notes</label>
                  <input type="checkbox" name="NotesFlag" id="NotesFlag" onChange={handelCBChange} />
                </div>
                <div className="checkboxCtn">
                  <label>Attachment</label>
                  <input type="checkbox" id="ContactAttachFlag" name="ContactAttachFlag" onChange={handelCBChange} />
                </div>
                <div className="checkboxCtn">
                  <label>Email Communication</label>
                  <input type="checkbox" id="EmailCommFlag" name="EmailCommFlag" onChange={handelCBChange} />
                </div>
              </div>
            </div>

            <div>
              <p style={{ fontWeight: 600, fontSize: '1.01rem' }}>
                Please select the team and stage where you want to {contactObj.label} the contact.
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '175px',
                  gap: '10px',
                  paddingTop: '15px',
                }}>
                <FormControl required={true} fullWidth={true}>
                  <p style={{ fontWeight: 600 }}>Select Team</p>
                  <Select maxMenuHeight={90} id="actionTeam" options={allTeams} onChange={updateStageDropDown} />
                </FormControl>
                <FormControl required={true} fullWidth={true}>
                  <p style={{ fontWeight: 600 }}>Select Stage</p>

                  <Select maxMenuHeight={90} id="actionStage" options={allStage} onChange={selectStage} />
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={applySelectedSelection}>
              Save changes
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default CopyMoveContact;

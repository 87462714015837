/* eslint-disable jsx-a11y/alt-text */
/**
 * Version 1.0.2
 * Vikas W
 * 13-10-2022
 * -
 * "Task Management"
 */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

// React icon
import ClearIcon from '@mui/icons-material/Clear';

import DescriptionInput from '../components/DescriptionInput';
import CommentsInput from '../components/CommentsInput';
import PageContainer from '../../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import Select from 'react-select';

/* Sidebar drawer */
import Drawer from '@mui/material/Drawer';
import { Button, Box, TextField, Card, CardContent, Typography, Divider, FormControl } from '@mui/material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsIcon from '@mui/icons-material/Collections';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import authHeader from '../../../../../services/auth/jwt/auth-header';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LightboxGallery from '../../../../../@focus/LightboxGallery';
import { CommentDisplay, ReadMore, timeLineStyle, readMoreCardStyles } from './CommentDisplay';
import DownloadIcon from '@mui/icons-material/Download';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import Modal from '@mui/material/Modal';
import fileDownload from 'js-file-download';
import Tooltip from '@mui/material/Tooltip';
import { getStore } from 'redux/store';
import { useHistory } from 'react-router';
// import { getStore } from '../../../../redux/store';
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ContributorsList from '../components/ContributorsList';
import TeamDataListView from '../TaskToolbarComponent.js/TeamDataListView';
import TaskContributorsAvatar from '../components/TaskContributorsAvatar';
import { makeStyles } from '@material-ui/core/styles';
import SelectContributors from '../components/SelectContributors';
import PriorityListDropdown from './PriorityListDropdown';
const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // alignItems: 'center',
    // paddingTop: '11px',
    // width: '15%',
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    // },
  },
}));

const styleTaskModalImage = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

/* Dropzone img preview css */
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  // marginBottom: 8,
  // marginRight: 8,
  margin: 0,
  width: 40,
  height: 40,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  alignItems: 'center',
  width: '100%',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const Modalestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

const TaskDrawer = ({
  openDrawer,
  selectedCard,
  toggleDrawer,
  onInputChangeHandler,
  getStatusHandler,
  TeamId,
  projectListData,
  handleDueDateChange,
  inputTeamChangeHandler,
  inputProjectChangeHandler,
  handleUpdateDescriptionInBoard,
  addNotification,
  descRef,
  commentRef,
  kanbanBoard,
  setMentionCount,
  mentionCount,
  priorityChangeHandler,
}) => {
  const dispatch = useDispatch();
  const store = getStore().getState();
  const { tmSelectedTeam } = store.common;
  /* Team Dropdown */
  const [openTeam, setOpenTeam] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [isEdtingDescription, setIsEdtingDescription] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [quillInputValue, setQuillInputValue] = useState('');
  const { tmTeamMembers } = store.common;
  const [selectedTeam, setSelectedTeam] = React.useState(null);

  const [dueDateValue, setDueDateValue] = React.useState(selectedCard?.dueDate ? new Date(selectedCard?.dueDate) : null);
  const [open, setOpen] = React.useState(false);
  const [openInitialProject, setOpenInitialProject] = useState(false);
  const [closeInitialProject, setCloseInitialProject] = useState(false);

  const handleDueDateHandler = (dateChange, cardId) => {
    setDueDateValue(dateChange);
    handleDueDateChange(dateChange, cardId);
  };

  const [getActivities, setGetActivities] = useState([]);
  const [members, setMembers] = useState([]);

  const [mentionTeamMembers, setMentionTeamMembers] = useState([]);
  const [mentionDescMembers, setMentionDescMembers] = useState([]);
  const [isSearchable, setIsSearchable] = useState(true);

  //    const [selectedCard, setSelectedCard] = useState(null);
  /* Comments Lists */
  const [comments, setComments] = useState(null);
  const [taskDescription, setTaskDescription] = useState(null);

  const [commentsData, setCommentsData] = useState([]);

  const [value, setValue] = useState(null);
  const [teams, setTeams] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState();
  const nodeRef = useRef(null);

  const closenodeRef = useRef(null);
  const [deleteAttachment, setDeleteAttachment] = useState([]);
  const [attachmentName, setAttachmentName] = useState();

  const [openModal, setOpenModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isClearable, setIsClearable] = useState(true);

  /* Dropzone files */
  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [files, setFiles] = useState([]);
  const responses = [];
  const [fileValue, setFileValue] = useState([]);
  const [replace, setReplace] = useState(false);
  const [replaceData, setReplaceData] = useState();
  const [assignTaskTeam, setAssignTaskTeam] = React.useState([]);
  const [checkProject, setCheckProject] = React.useState([]);
  const [showFocus, setShowFocus] = React.useState(false);
  const selectRef = useRef(null);
  const [pngData, setPngData] = useState('');
  const [isTMImagePDF, setIsTMImagePDF] = useState(null);

  const [openTaskModalImage, setOpenTaskModalImage] = React.useState(false);
  const handleOpenTaskModalImage = () => setOpenTaskModalImage(true);
  const handleCloseTaskModalImage = () => setOpenTaskModalImage(false);

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleViewButtonClick = file => {
    // dispatch({ type: FETCH_START });
    // customAxios
    //   .get('/Task/GetAttachments?tmTeamId=' + TeamId + '&tId=' + selectedCard.id, { headers: authHeader() })
    //   .then(data => {
    //     dispatch({ type: FETCH_SUCCESS });
    //     console.log(data, 'data');
    //   })

    //   .catch(error => console.error(error));
    const bodyDescription = {
      Id: file?.Id,
      tId: selectedCard.id,
      tmTeamId: TeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/DownloadAttachment', bodyDescription, { headers: authHeader(), responseType: 'blob' })
      .then(async res => {
        const base64 = await convertBase64(res.data);
        setPngData(base64);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  // dispatch({ type: FETCH_START });
  // customAxios
  //   .get('/Task/GetAttachments?tmTeamId=' + TeamId + '&tId=' + selectedCard.id, { headers: authHeader() })
  //   .then(({ data, status }) => {
  //     dispatch({ type: FETCH_SUCCESS });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  // console.log(checkProject, 'checkProject');

  const fileDataCheck = () => {};

  const handleOpen = file => {
    setDeleteAttachment(file);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  const edithandleClose = () => {
    setOpenEditModal(false);
    imageDataUpdate();
  };

  useEffect(() => {
    /* START: Close/open team selection dropdown on outside click or inside click */
    // const drop = document.querySelectorAll('.customAvatar');
    // drop.forEach(drp => {
    //   drp.addEventListener('click', event => {
    //     const boxes = document.querySelectorAll('.dropdown-s');
    //     for (const box of boxes) {
    //       box.classList.remove(
    //         "dropdown-s-active"
    //       );
    //     }
    //     // event.target.nextElementSibling.classList.add("dropdown-s-active");
    //     setTimeout(() => {
    //       // console.log(event.target.className)
    //       event.target.nextElementSibling.classList.add("dropdown-s-active");
    //     }, 1000);
    //   });
    // });
    // document.getElementById('root').onclick = function (e) {
    //   const drop = document.querySelectorAll('.customAvatar');
    //   // console.log(!e.target.className.includes('customAvatar'))
    //   if (!e.target.className.includes('customAvatar')) {
    //     const boxes = document.querySelectorAll('.dropdown-s');
    //     for (const box of boxes) {
    //       box.classList.remove(
    //         "dropdown-s-active"
    //       );
    //     }
    //   }
    // }
    /* END: Close/open team selection dropdown on outside click or inside click */

    if (tmTeamMembers.length > 0) {
      // console.log(tmTeamMembers);
      var users = tmTeamMembers.map(c => ({ value: c.TMUserId, label: c?.Email, TeamId: c.TeamId, IsAdmin: c.IsAdmin }));
      setAssignTaskTeam(users);
    }
    if (tmSelectedTeam) {
      // console.log(tmSelectedTeam);
      setSelectedTeam(tmSelectedTeam);
    }
  }, [tmTeamMembers, tmSelectedTeam]);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*', 'video/*', 'application/*'],
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      );

      acceptedFiles.map(
        file => {
          let filterData = files.filter(
            filename => filename?.tAttachmentName === file?.name,
            //   {
            //   if (filename?.tAttachmentName === file?.name) {
            //     setReplaceData(filename);
            //     setOpenEditModal(true);
            //   }
            // }
          );
          if (filterData.length) {
            setOpenEditModal(true);
            setAttachmentName(file?.name);
          }
          const formdata = new FormData();
          formdata.append('tId', selectedCard.id);
          formdata.append('tmTeamId', TeamId);
          formdata.append('flagOverwrite', filterData?.length ? 1 : 0);
          formdata.append('file', file);

          filterData.length ? setReplaceData(formdata) : updateStages(formdata);
          // setReplaceData(formdata);

          // function readFileDataAsBase64(e) {
          //   return new Promise((resolve, reject) => {
          //     const reader = new FileReader();

          //     reader.onload = event => {
          //       resolve(event.target.result);
          //     };

          //     reader.onerror = err => {
          //       reject(err);
          //     };

          //     reader.readAsDataURL(e);
          //   }).then(function(result) {
          //     //Create a new attachment to store in the DB
          //     const formdata = new FormData();
          //     formdata.append('tId', selectedCard.id);
          //     formdata.append('tmTeamId', TeamId);
          //     formdata.append('flagOverwrite', 0);
          //     formdata.append('file', acceptedFiles[0]);
          //     // const bodyAddAttachment = {
          //     //   tId: TeamId,
          //     //   tmTeameId: selectedCard.id,
          //     //   flagOverwrite: 0,
          //     //   file: result,
          //     //   // file: file.name,
          //     // };
          //     //   const requestOptions = {
          //     //     method: 'POST',
          //     //     headers: authHeader(),
          //     //     body: JSON.stringify(bodyAddAttachment)
          //     //   };
          //     //   fetch('http://api.focus247.online/Task/AddAttachment', requestOptions);
          //     console.log(formdata, 'formdata');
          //     updateStages(formdata);
          //     // responses.push(await customAxios.post('/Task/AddAttachment', bodyAddAttachment, { headers: authHeader() }));
          //   });
        },

        // readFileDataAsBase64(file);
      );
    },
  });
  const updateStages = async obj => {
    responses.push(await customAxios.post('/Task/AddAttachment', obj, { headers: authHeader() }));
    imageDataUpdate();
  };

  const EditAttachmentFile = () => {
    updateStages(replaceData);
    setOpenEditModal(false);
  };
  const handleDownload = file => {
    const bodyDescription = {
      Id: file?.Id,
      tId: selectedCard.id,
      tmTeamId: TeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/DownloadAttachment', bodyDescription, { headers: authHeader(), responseType: 'blob' })
      .then(res => {
        fileDownload(res.data, file?.tAttachmentName);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const thumbs = files?.map((file, index) => {
    // const isPDF = file?.tAttachmentName?.split('.')[1] === 'pdf';

    let type = file?.name?.split('.')[1];
    return (
      <div key={file?.name} className="d-flex flex-column m-1" style={{ width: '12rem' }}>
        <Tooltip
          title={file?.tAttachmentName}
          placement="top"
          arrow
          className="d-flex flex-row"
          style={{ cursor: 'pointer' }}>
          <div className="d-flex flex-row">
            <div style={thumb} styles={{ display: 'flex', flexDirection: 'column' }}>
              <div style={thumbInner}>
                {type === 'pdf' ? (
                  <PictureAsPdfIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'doc' || type === 'dot' ? (
                  <TextSnippetOutlinedIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'xls' || type === 'xlt' || type === 'xla' || type === 'xlsx' ? (
                  <DescriptionIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'txt' || type === 'csv' || type === 'odt' || type === 'pptx' ? (
                  <TextSnippetIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'movie' ||
                  type === 'mp4' ||
                  type === 'ogv' ||
                  type === 'webm' ||
                  type === 'wmv' ||
                  type === 'avi' ||
                  type === 'f4v' ||
                  type === 'flv' ||
                  type === 'mpeg' ? (
                  <VideoFileIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'png' || type === 'jpeg' || type === 'jpg' || type === 'gif' ? (
                  <CollectionsIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : (
                  <InsertDriveFileIcon style={{ width: 40, height: 29, color: 'red' }} />
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '5px',
              }}>
              <p style={{ fontSize: '11px', fontWeight: 700 }}>
                {/* {file?.tAttachmentName} */}
                {file?.tAttachmentName?.length > 20 ? file?.tAttachmentName.substring(0, 20) + '...' : file?.tAttachmentName}
              </p>
              <p style={{ fontSize: '8px', color: 'green' }}>
                {moment
                  .utc(file?.tAttachmentDate)
                  .local()
                  .format('DD-MM-YYYY')}{' '}
                at {''}
                {moment
                  .utc(file?.tAttachmentDate)
                  .local()
                  .format('hh:mm A')}
              </p>
            </div>
          </div>
        </Tooltip>
        <div className="d-flex flex-row justify-content-between pt-1 px-1">
          <div
            onClick={() => {
              handleViewButtonClick(file);
              handleOpenTaskModalImage();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              color: 'blue',
              cursor: 'pointer',
              fontSize: '12px',
            }}>
            Preview
          </div>
          <div
            onClick={() => handleDownload(file)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              color: 'blue',
              cursor: 'pointer',
              fontSize: '12px',
            }}>
            Download
          </div>
          <div
            onClick={() => handleOpen(file)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              color: '#b31616',
              cursor: 'pointer',
              fontSize: '12px',
            }}>
            Delete
          </div>
        </div>

        <Modal
          open={openTaskModalImage}
          onClose={handleCloseTaskModalImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={styleTaskModalImage}>
            <img src={pngData} alt="Attached File" />
          </Box>
        </Modal>
      </div>
    );
  });
  // const files = acceptedFiles.map(file => (
  //     <ListItem selected key={file.path} sx={{ width: 'auto', mr: 1 }}>
  //         {file.path} - {file.size} bytes
  //         {/* <img src={URL.createObjectURL(file)} style={{width: '50px'}} alt=""/> */}
  //     </ListItem>
  // ));

  const imageDataUpdate = () => {
    let attach = [];
    dispatch({ type: FETCH_START });
    customAxios
      .get('/Task/GetAttachments?tmTeamId=' + TeamId + '&tId=' + selectedCard.id, { headers: authHeader() })
      .then(({ data, status }) => {
        dispatch({ type: FETCH_SUCCESS });
        let id = 1;
        setFiles(data);

        // let imageData = data.map(c => {
        //   console.log(c, 'cdsfkldjfksdjfklsd');
        //   return {
        //     Id: c.Id,
        //     UserId: c.UserId,
        //     tAttachmentDate: c.tAttachmentDate,
        //     tAttachmentFullPath: c.tAttachmentFullPath,
        //     tAttachmentName: c.tAttachmentName,
        //     tDateModified: c.tDateModified,
        //     tId: c.tId,
        //   };
        // });

        // data.forEach(oneAttach => {
        //   console.log(oneAttach, 'oneAttach');
        //   //convert base64 string to file data
        //   function dataURLtoFile(dataurl, filename) {
        //     console.log(filename, 'dataurl');
        //     var arr = filename?.split('.'),
        //       mime = arr[0].match(/:(.*?);/)[1],
        //       bstr = atob(arr[1]),
        //       n = bstr.length,
        //       u8arr = new Uint8Array(n);

        //     while (n--) {
        //       u8arr[n] = bstr.charCodeAt(n);
        //     }
        //     return new File([u8arr], filename, { type: mime });
        //   }

        //   let fileData = dataURLtoFile(oneAttach.tAttachmentFullPath, oneAttach.tAttachmentName);
        //   console.log(fileData, 'fileData');
        //   let newFile = {
        //     id: id,
        //     file: fileData,
        //     metaData: { type: fileData.type, size: fileData.size },
        //     name: fileData.name,
        //     thumbnail: URL.createObjectURL(fileData),
        //     fullImage: URL.createObjectURL(fileData),
        //     isCoved: false,
        //     type: 'attachment',
        //     createdAt: oneAttach.tAttachmentDate,
        //     //   user: kanbanBoard.members[0],
        //   };
        //   attach.push(newFile);
        //   id++;
        // });
        setGetActivities(attach);
      });
  };

  useEffect(() => {
    imageDataUpdate();

    getComments();
  }, [selectedCard]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  // console.log(files, 'files');
  const containsWhitespace = function me(node) {
    var childNodes = node.childNodes;

    if (childNodes.length == 0) {
      if (node.nodeName == 'IMG') return false;
      return true;
    }

    for (var i = 0, length = childNodes.length; i < length; i++) {
      if (childNodes[i].nodeType == 1) {
        return me(childNodes[i]);
      } else if (childNodes[i].nodeType == 3) {
        let t = !childNodes[i].data.replace(/\s+/g, '');
        return t;
      }
    }
  };

  function getCorrectedHTML(s) {
    let test = document.createElement('div');
    test.innerHTML = s;
    let el = [].slice.call(test.getElementsByTagName('div'));

    el.filter(containsWhitespace).forEach(function(element) {
      element.remove();
    });
    return { data: test.innerHTML, len: test.childNodes.length };
  }
  // function extractContent(s) {
  //   var span = document.createElement('span');
  //   span.innerHTML = s;
  //   // console.log(span.children);
  //   // console.log(s);

  //   return span.getElementsByTagName('img').length > 0 || span.innerText.replace(/\s/g, '').length != 0;
  // }

  /* Get All comments by Task ID */
  const getComments = () => {
    customAxios
      .get('/Task/GetComments?tmTeamId=' + TeamId + '&tId=' + selectedCard.id, { headers: authHeader() })
      .then(({ data, status }) => {
        let commentData = [];
        data.forEach(oneComment => {
          commentData.push({
            id: oneComment.Id,
            user: {
              id: oneComment.UserId,
              name: oneComment?.Email,
              profilePic: 'https://via.placeholder.com/150x150',
              email: oneComment?.Email,
            },

            comment: oneComment.tComments,
            type: 'comment',
            createdAt: oneComment.tCommentDate,
          });
        });
        // console.log(commentData)
        setCommentsData(commentData);
      });
  };
  /* Add comments against Task ID */
  const addCommentHandler = () => {
    const value = commentRef.current.state.value;
    const updatedValue = value.replace(/\u{0}+|\u{feff}+|\u{200b}+|\u{200c}+|\u{200d}/gu, '');

    let correctedHtmlObj = getCorrectedHTML(updatedValue);

    if (updatedValue && correctedHtmlObj.len) {
      // console.log(selectedCard)
      let commentObj = {
        TaskId: selectedCard.id,
        Comment: correctedHtmlObj.data,
      };

      const noteObj = {
        UserIdTo: selectedCard.AssignedTo,
        ActivityId: selectedCard.id,
        NotifyMessage: 'New Comment on Task Ref:' + selectedCard.tRefNumber,
        FunctionalityId: selectedCard.StatusId,
        TeamId: selectedCard.TeamId,
        IsClicked: 0,
      };
      const emailObj = {
        type: 'comment',
        cardData: selectedCard,
        doneBy: null,
        message: correctedHtmlObj.data,
      };
      console.log({ noteObj, emailObj });
      addNotification(noteObj, emailObj);

      // console.log(commentObj, 'commentObj');
      dispatch({ type: FETCH_START });
      customAxios
        .post('/Task/AddComment', commentObj, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          // sendNotification()
          setComments(null);
          // editorRef.current.state.value = null;
          commentRef.current.setEditorContents(commentRef.current.editor, null);
          getComments();
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };
  const handleCommentEditor = useCallback((value, delta, source, editor) => {
    // const text = editor.getContents();
  });

  const handleDescChangeEditor = (html, delta, source) => {
    // console.log(html);
    // setTaskDescription(html);
  };
  const handleDescBlurEditor = () => {
    // setTimeout(() => {

    const bodyDescription = {
      ColumnName: 'TaskDescription',
      PrimaryKeyValue: selectedCard.id,
      NewValue: descRef?.current?.state?.value,
      TableName: 'TM_Tasks',
      TeamId: TeamId,
    };
    if (selectedCard.description === descRef?.current?.state?.value) return;
    selectedCard.description = descRef?.current?.state?.value;
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', bodyDescription, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getStatusHandler();
        handleUpdateDescriptionInBoard(descRef?.current?.state?.value, selectedCard.id);
        setIsEdtingDescription(prevState => false);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        setIsEdtingDescription(prevState => false);
      });

    // }, 50);
  };

  /* START: Team Members */
  const setTeamMemberSelectStatus = (args, selectStatus) => {
    let currMembersStatus = members;
    currMembersStatus.forEach(item => {
      if (item.value == args.value) {
        item.isSelectedMenber = selectStatus;
      } else {
        item.isSelectedMenber = !selectStatus;
      }
    });
    setMembers(currMembersStatus);
    setOpenTeam(false);
  };
  const chooseTeamHandler = args => {
    // console.log(args, 'args');
    setTeamMemberSelectStatus(args, true);
    setCloseInitialProject(false);
    setTeams(args);
    inputTeamChangeHandler(args, selectedCard?.id, TeamId);
    setOpenTeam(false);
  };

  useEffect(() => {
    getTaskTeamMembers();
    console.log(selectedCard);
  }, []);

  const DeleteAttachmentFile = () => {
    let newFiles = [];
    const bodyDescription = {
      Id: deleteAttachment?.Id,
      tId: selectedCard.id,
      tmTeamId: TeamId,
    };
    files.filter(object => {
      if (object?.Id !== deleteAttachment?.Id) {
        newFiles = object;
      }
    });
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/DeleteAttachment', bodyDescription, { headers: authHeader() })
      .then(data => {
        setOpenModal(false);
        imageDataUpdate();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const setProjectSelectStatus = (args, selectStatus) => {
    let currProjectStatus = projectList;
    currProjectStatus.forEach(item => {
      if (item.value == args.value) {
        item.isSelectedProject = true;
      } else {
        item.isSelectedProject = false;
      }
    });
    // console.log('setProjectList', currProjectStatus);
    setProjectList(currProjectStatus);
  };

  const chooseProjectHandler = args => {
    setProjectSelectStatus(args, true);
    setOpenInitialProject(false);
    setProject(args);
    inputProjectChangeHandler(args, selectedCard.id, TeamId);
    // setIsClearable(state => !state);
    setOpenProject(false);
  };

  /* Get All Users */
  const getTaskTeamMembers = () => {
    customAxios.get('/TaskTeams/GetTaskTeamMembers/' + TeamId, { headers: authHeader() }).then(({ data }) => {
      if (data) {
        console.log(data);
        var users = data.map(c => ({
          value: c.TMUserId,
          label: c?.Email,
          isSelectedMenber: c.TMUserId == (teams ? teams.value : selectedCard.AssignedTo) ? true : false,
          image: c.AvatarImage,
        }));
        var mentionTeamList = data.map(c => ({
          value: c?.Email,
          id: c.TMUserId,
          label: c?.Email,
          text: c?.Email,
          image: c.AvatarImage,
        }));
        setMembers(users);
        setMentionTeamMembers(mentionTeamList);
        // setMentionDescMembers(mentionTeamList);
      }
    });
  };

  // Check project
  useEffect(() => {
    CheckProjectIsThere();
  }, []);

  /* END: Team Members */
  useEffect(() => {
    if (projectListData) {
      var project = projectListData.map(c => ({
        value: c.pId,
        label: c.pName,
        isSelectedProject: c.pId == (project ? project.value : selectedCard.ProjectId) ? true : false,
        ...c,
      }));
      setProjectList(project);
    }
  }, [projectListData]);

  /* START: Team  Dropdown selection  */
  const customDropdownStyles = {
    option: (provided, isSelected) => ({
      ...provided,
      // color: isSelected ? 'red' : 'blue',
      width: 300,
    }),
    container: () => ({
      width: 300,
    }),
    control: () => ({
      border: 'none',
      display: 'none',
      width: 0,
    }),
    menu: () => ({
      width: 300,
      background: 'white',
      border: '1px solid #e1e1e1',
      // marginTop: '13px',
    }),
    menuList: () => ({
      width: 300,
      height: 250,
      overflowX: 'hidden',
      overflowY: 'scroll',
      scrollbarWidth: '0px',
      webkitScrollbarWidth: 'none',
      msScrollbarWidth: 'none',
    }),
  };
  const customDropdownStylesLabel = {
    option: (provided, isSelected) => ({
      ...provided,
      // color: isSelected ? 'red' : 'blue',
      width: 300,
    }),
    container: () => ({
      width: 300,
    }),
    control: () => ({
      border: 'none',
      display: 'none',
      width: 0,
    }),
    menu: () => ({
      width: 300,
      background: 'white',
      border: '1px solid #e1e1e1',
      marginTop: '13px',
      marginLeft: '-13px',
    }),
    menuList: () => ({
      width: 300,
      height: 250,
      overflowX: 'hidden',
      overflowY: 'scroll',
      scrollbarWidth: '0px',
      webkitScrollbarWidth: 'none',
      msScrollbarWidth: 'none',
    }),
  };

  const customStyle = {
    option: (provided, isSelected) => ({
      ...provided,
      // color: isSelected ? 'red' : 'blue',
      width: 300,
    }),
    container: () => ({
      width: 300,
    }),
    control: () => ({}),
    menu: () => ({}),
    menuList: () => ({}),
  };
  const returnAvatarName = (teamId, isImg) => {
    const teamObj = members.filter(c => c.value == teamId);
    if (teamObj.length > 0) {
      if (teamObj[0].image && isImg) {
        return (
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={`data:image/jpeg;base64,${teamObj[0].image}`}
          />
        );
      } else if (!teamObj[0].image && isImg) {
        return teamObj[0]?.label?.substring(0, 2);
      } else {
        return teamObj[0]?.label;
      }
    }
    return 'None';
  };
  const returnProjectName = ProjectId => {
    const teamObj = projectList.filter(c => c.value == ProjectId);
    if (teamObj.length > 0) {
      return teamObj[0]?.label;
    }
    return 'Add Project';
  };
  /* END: Team  Dropdown selection  */

  const getProjectBackGroundColor = ProjectId => {
    const currentProject = projectList.filter(c => c.value == ProjectId);
    if (currentProject.length > 0) {
      return currentProject[0].pHexColor || 'rgba(0, 0, 0, 0.08)';
    }
    return 'rgba(0, 0, 0, 0.08)';
  };
  const getProjectFontColor = ProjectId => {
    // return project?.pHexFontColor || 'rgba(0, 0, 0, 0.08)';
    const currentProject = projectList.filter(c => c.value == ProjectId);
    if (currentProject.length > 0) {
      return currentProject[0].pHexFontColor || 'rgba(0, 0, 0, 0.08)';
    }
    return 'rgba(0, 0, 0, 0.08)';
  };
  useEffect(() => {
    if (selectedCard?.description) {
      // setTimeout(() => {
      setTaskDescription(selectedCard?.TaskDescription);
      // }, 1000);
    }
    return () => {
      setTaskDescription('');
    };
  }, [selectedCard?.description]);

  const handleClickOutside = event => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setOpenProject(false) || setOpenInitialProject(false);
    }
  };

  const handleClickOutSideDropdown = event => {
    if (closenodeRef.current && !closenodeRef.current.contains(event.target)) {
      return setOpenTeam(false) || setCloseInitialProject(false);
    }
    // console.log(openTeam, 'openTeam');
  };

  const handleDescKeyDown = event => {
    setIsEdtingDescription(true);
    const quill = descRef.current.getEditor();
    // Your code to handle the event goes here
    if (event.key === 'Tab') {
      event.preventDefault();
      quill.insertText(quill.getSelection().index, '  ');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSideDropdown, true);
    return () => {
      document.removeEventListener('click', handleClickOutSideDropdown, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const removeAssigni = () => {
    let currAssign = members.find(c => c.value == (teams ? teams.value : selectedCard.AssignedTo));
    let args = {
      value: '',
      label: '',
      isSelectedMenber: 'true',
    };
    setTeamMemberSelectStatus(args, true);
    setTeams(args);
    setCloseInitialProject(false);
    inputTeamChangeHandler(args, selectedCard.id, TeamId);
    setOpenTeam(false);
  };
  // const projectDropdown = () => {
  //   const projectData = projectList.filter(c => c.pTeamId === selectedCard.tmTeamId);
  //   return projectData;
  // };
  // projectDropdown();
  // console.log(selectedCard, 'selectedCard');

  const changePage = () => {
    history.push('/planning/project-page');
  };

  const CheckProjectIsThere = () => {
    const data = projectList.filter(id => id.isSelectedProject == false).filter(c => c.pTeamId === selectedCard.tmTeamId);
    setCheckProject(data);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFocus(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleSelectFocus = () => {
    setOpenProject(!openProject);
  };

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectRef.current]);

  const onEditorStateChange = editorState => {};

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContents, setConvertedContents] = useState(null);

  function handleEditorChange(state) {
    setEditorState(state);
    const content = JSON.stringify(convertToRaw(state.getCurrentContent()));
    // localStorage.setItem('editorContent', content);
  }

  // for new editer code

  const addCommentHandlerdata = () => {
    // setConvertedContent(draftToHtml(editorState));
    const value = convertedContent;
    const updatedValue = value.replace(/\u{0}+|\u{feff}+|\u{200b}+|\u{200c}+|\u{200d}/gu, '');

    let correctedHtmlObj = getCorrectedHTML(updatedValue);

    if (updatedValue && correctedHtmlObj.len) {
      // console.log(selectedCard)
      let commentObj = {
        TaskId: selectedCard.id,
        Comment: correctedHtmlObj.data,
      };

      // console.log(selectedCard)

      const noteObj = {
        UserIdTo: selectedCard.AssignedTo,
        ActivityId: selectedCard.id,
        NotifyMessage: 'New Comment on Task Ref:' + selectedCard.tRefNumber,
        FunctionalityId: selectedCard.StatusId,
        TeamId: selectedCard.TeamId,
        IsClicked: 0,
      };
      const emailObj = {
        type: 'comment',
        cardData: selectedCard,
        doneBy: null,
        message: correctedHtmlObj.data,
      };
      addNotification(noteObj, emailObj);

      // console.log(commentObj, 'commentObj');
      dispatch({ type: FETCH_START });
      customAxios
        .post('/Task/AddComment', commentObj, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          // sendNotification()
          setComments(null);
          setEditorState(EditorState.createEmpty());
          // editorRef.current.state.value = null;
          // commentRef.current.setEditorContents(commentRef.current.editor, null);
          getComments();
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  const handleCommentsContentStateChange = contentState => {
    setConvertedContent(draftToHtml(contentState));
  };

  const handleCommentEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const uploadImageCallBack = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const imageUrl = event.target.result;
        resolve({ data: { link: imageUrl } });
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'image', 'history', 'colorPicker'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right'],
    },
    image: {
      uploadCallback: uploadImageCallBack,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    },
  };

  const [editorStates, setEditorStates] = useState(() => EditorState.createEmpty());

  // setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(<p>my text</p>))));

  const onEditorStateChanges = newEditorState => {
    setEditorStates(newEditorState);
  };

  const handleContentStateChanges = contentState => {
    setConvertedContents(draftToHtml(contentState));
  };
  const handleDescBlurEditors = () => {
    const bodyDescription = {
      ColumnName: 'TaskDescription',
      PrimaryKeyValue: selectedCard.id,
      NewValue: convertedContents,
      TableName: 'TM_Tasks',
      TeamId: TeamId,
    };
    if (selectedCard.description === convertedContents) return;
    selectedCard.description = convertedContents;
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', bodyDescription, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getStatusHandler();
        handleUpdateDescriptionInBoard(convertedContents, selectedCard.id);
        setIsEdtingDescription(prevState => false);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        setIsEdtingDescription(prevState => false);
      });
  };

  const htmlToDraftBlocks = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  useEffect(() => {
    if (taskDescription) {
      setEditorStates(htmlToDraftBlocks(taskDescription));
      focusEditor();
    }
  }, [taskDescription]);

  const editorRef = useRef(null);

  const focusEditor = () => {
    if (editorRef && editorRef.current) {
      editorRef.current.focusEditor();
    }
  };

  const [taskContributors, setTaskContributors] = useState([]);
  const [contributorLoader, setContributorLoader] = useState(true);
  const classes = useStyles();
  // const [mentionCount, setMentionCount] = useState(0);
  const [getDeleteContributorId, setGetDeleteContributorId] = useState(null);

  const getTaskContributors = () => {
    dispatch({ type: FETCH_START });

    customAxios
      .get(`Task/GetTaskContributors?tmTeamId=${TeamId}&tmTaskId=${selectedCard.id}`, { headers: authHeader() })
      .then(({ data }) => {
        console.log(data, 'getTaskContributors');
        if (data) {
          setTaskContributors(data);
          dispatch({ type: FETCH_SUCCESS });
          setContributorLoader(false);
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error ss' });
      });
  };

  const deleteTaskContributors = contributorId => {
    const bodyDescription = {
      tmTeamId: TeamId,
      tmTaskId: selectedCard.id,
      contributorId: contributorId,
    };
    dispatch({ type: FETCH_START });

    customAxios
      .post('Task/DeleteTaskContributors', bodyDescription, {
        headers: authHeader(),
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          setMentionCount(prevState => prevState + 1);
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        return error;
      });
  };

  useEffect(() => {
    if (getDeleteContributorId) {
      setMentionCount(prevState => prevState + 1);
      deleteTaskContributors(getDeleteContributorId);
    }
  }, [getDeleteContributorId]);

  useEffect(() => {
    getTaskContributors();
  }, [TeamId, selectedCard?.id, mentionCount]);
  useEffect(() => {
    if (commentRef && commentRef.current) {
      console.log(commentRef.current);
      commentRef.current.blur();
    }
  }, [commentRef.current]);
  // useEffect(() => {
  //   if (taskContributors.length > 0) {
  //     getTaskContributors();
  //   }
  // }, [taskContributors]);

  return (
    <PageContainer heading={<IntlMessages id="pages.taskmanagement" />}>
      <React.Fragment>
        <Drawer
          // variant="persistent"
          anchor={'right'}
          open={openDrawer}
          onClose={toggleDrawer()}>
          {selectedCard && (
            <div style={{ width: '60vw' }}>
              <div className="cardDetails">
                <Card sx={{ minWidth: 275 }} style={{ margin: '1rem', boxShadow: 'none', overflow: 'inherit' }}>
                  <CardContent component="div">
                    <div
                      style={{
                        fontSize: '16px',
                        fontFamily: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 1.235,
                        padding: '10px 0px',
                      }}>
                      <strong style={{ padding: '0 16px', fontWeight: 800, fontSize: 18 }}>
                        {selectedTeam?.TMPrefix}:{selectedCard && selectedCard?.tRefNumber}
                      </strong>
                      <TextField
                        label="Title"
                        id="TaskName"
                        name="TaskName"
                        type="text"
                        // value={}
                        // onChange={onChange}
                        onBlur={event => onInputChangeHandler(event, selectedCard?.id)}
                        defaultValue={selectedCard?.title}
                        size="small"
                        InputLabelProps={{ shrink: false }}
                        style={{ width: '100%' }}
                        inputProps={{ style: { fontSize: '16px', fontWeight: 'bold', padding: '15px 15px' } }}
                      />
                    </div>
                    {/* <Button color="primary" onClick={getTaskContributors}>
                      Click me
                    </Button> */}
                    <form autoComplete="off">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3 mb-3 align-items-center d-flex">
                            <label className="cardDetailsLabel">Due Date</label>
                          </div>
                          <div className="col-md-9 mb-3">
                            <div className="">
                              <div className="task-duedate-drawer" onClick={() => setOpen(true)}>
                                <Button>
                                  <CalendarMonthIcon />
                                </Button>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    minDate={moment().subtract(2, 'years')}
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    label={dueDateValue && 'Due Date'}
                                    value={dueDateValue}
                                    // onChange={handleDueDateChange}
                                    onChange={event => handleDueDateHandler(event, selectedCard.id)}
                                    disableOpenPicker
                                    clearable={true}
                                    renderInput={params => <TextField {...params} />}
                                    componentsProps={{
                                      actionBar: { actions: ['clear'] },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3 align-items-center d-flex">
                            <label className="cardDetailsLabel">Assignee</label>
                          </div>
                          <div className="col-md-9 mb-3 teams">
                            <div className="">
                              {/* labelCantainer label-teams teams-container */}
                              <FormControl
                                onClick={() => setOpenTeam(!openTeam)}
                                style={{ zIndex: 9999, marginRight: '5px', width: '100%', position: 'absolute' }}
                                size="small">
                                <div
                                  style={{ display: 'flex', alignItems: 'center', marginLeft: '-4px' }}
                                  className="project-span">
                                  <Avatar
                                    children={
                                      members && returnAvatarName(teams ? teams.value : selectedCard.AssignedTo, true)
                                    }
                                    sx={{ width: 24, height: 24 }}
                                    style={{ cursor: 'pointer', fontSize: 14 }}
                                  />

                                  <span style={{ marginLeft: 23 }}>
                                    {members && returnAvatarName(teams ? teams.value : selectedCard.AssignedTo, false)}
                                  </span>
                                  {openTeam ||
                                  !members.find(c => c.value == (teams ? teams.value : selectedCard.AssignedTo)) ? (
                                    ''
                                  ) : (
                                    <span
                                      style={{ marginLeft: 23, cursor: 'pointer' }}
                                      onClick={e => {
                                        removeAssigni();
                                      }}>
                                      <ClearIcon fontSize="small" onClick={() => setOpenTeam(false)} />
                                    </span>
                                  )}
                                </div>
                                <div ref={closenodeRef}>
                                  {openTeam && (
                                    <Select
                                      // style={{ zIndex: 9999, marginRight: '5px', marginTop: '13px' }}
                                      styles={customDropdownStylesLabel}
                                      menuIsOpen={openTeam}
                                      placeholder={'Select Team..'}
                                      options={members.filter(c => c?.isSelectedMenber == false)}
                                      value={members.find(
                                        c => c?.value == (teams ? teams?.value : selectedCard?.AssignedTo),
                                      )}
                                      onChange={chooseTeamHandler}
                                    />
                                  )}
                                </div>
                              </FormControl>
                            </div>
                          </div>

                          <div className="col-md-3 mb-3 d-flex align-items-center">
                            <label className="cardDetailsLabel">Contributors</label>
                          </div>
                          {/* {taskContributors.length > 0 && mentionTeamMembers.length && ( */}
                          <div className="col-md-9 mb-3 teams d-flex justify-content-start align-items-center">
                            {taskContributors.length > 0 && mentionTeamMembers.length ? (
                              <TaskContributorsAvatar
                                mentionTeamMembers={mentionTeamMembers}
                                deleteTaskContributors={deleteTaskContributors}
                                taskContributors={taskContributors}
                                setGetDeleteContributorId={setGetDeleteContributorId}
                              />
                            ) : (
                              <>
                                {contributorLoader ? (
                                  <div className={classes.root}>
                                    <CircularProgress />
                                  </div>
                                ) : (
                                  <>
                                    <span className="mr-3"> No contributors</span>
                                  </>
                                )}
                              </>
                            )}
                            <div className="d-flex align-items-center">
                              {/* <AddCircleOutlineIcon color="primary" /> */}
                              <SelectContributors
                                mentionTeamMembers={mentionTeamMembers}
                                tmTeamId={TeamId}
                                tmTaskId={selectedCard.id}
                                addNotification={addNotification}
                                selectedCard={selectedCard}
                                getTaskContributors={getTaskContributors}
                              />
                            </div>
                          </div>
                          {/* )} */}

                          {/* <div className="col-md-3 mb-3 d-flex align-items-center">
                            <label className="cardDetailsLabel">contributors</label>
                          </div>
                          <div className="col-md-9 mb-3 teams d-flex">
                            
                            <TaskContributorsAvatar
                              kanbanBoard={kanbanBoard}
                              deleteTaskContributors={deleteTaskContributors}
                              taskContributors={taskContributors}
                              setGetDeleteContributorId={setGetDeleteContributorId}
                            />
                          </div> */}
                          <div className="col-md-3 mb-3 d-flex align-items-center">
                            <label className="cardDetailsLabel">Projects</label>
                          </div>
                          <div className="col-md-9 mb-3 teams">
                            <div className="">
                              <div className="project-add">
                                <FormControl style={{ width: '100%' }} size="small">
                                  <div className="d-flex flex-row">
                                    <div style={{ width: '300px' }}>
                                      <div className="project-span ">
                                        {(!project && !selectedCard.ProjectId) ||
                                        returnProjectName(project ? project.value : selectedCard.ProjectId) ===
                                          'Add Project' ? (
                                          projectList
                                            .filter(id => id.isSelectedProject == false)
                                            .filter(c => c.pTeamId === selectedCard.tmTeamId).length ? (
                                            <div
                                              className="selected-border teams-container"
                                              style={{
                                                alignItems: 'center',
                                                padding: '3px 12px',
                                                placeholder: 'Select Project',
                                              }}>
                                              <span
                                                // onClick={() => {
                                                //   setOpenProject(!openProject);
                                                // }}
                                                onClick={handleSelectFocus}
                                                style={{ marginRight: '5px' }}>
                                                {projectList &&
                                                  returnProjectName(project ? project?.value : selectedCard?.ProjectId)}
                                              </span>
                                              <KeyboardArrowDownOutlinedIcon
                                                // onClick={() => {
                                                //   setOpenProject(false);
                                                // }}
                                                onClick={handleSelectFocus}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="selected-border teams-container"
                                              style={{
                                                alignItems: 'center',
                                                padding: '3px 12px',
                                                fontSize: '14px',
                                              }}>
                                              Project Not Found
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            style={{
                                              display: 'flex',
                                              cursor: 'pointer',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              backgroundColor: getProjectBackGroundColor(
                                                project ? project.value : selectedCard.ProjectId,
                                              ),
                                              width: 'fit-content',
                                              padding: '3px 12px',
                                              borderRadius: '13px',
                                              color: getProjectFontColor(project ? project.value : selectedCard.ProjectId),
                                            }}>
                                            <span
                                              // onClick={() => {
                                              //   setOpenProject(!openProject);
                                              // }}
                                              onClick={handleSelectFocus}
                                              style={{ marginRight: '5px' }}>
                                              {projectList &&
                                                returnProjectName(project ? project.value : selectedCard.ProjectId)}
                                            </span>
                                            <KeyboardArrowDownOutlinedIcon
                                              // onClick={() => {
                                              //   setOpenProject(!openProject);
                                              // }}
                                              onClick={handleSelectFocus}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {projectList
                                        .filter(id => id.isSelectedProject === false)
                                        .filter(c => c.pTeamId === selectedCard.tmTeamId).length ? (
                                        <div ref={nodeRef} className="select-drop">
                                          {openProject && (
                                            <Select
                                              ref={selectRef}
                                              // styles={customDropdownStyles}
                                              autoFocus={true}
                                              openMenuOnFocus={true}
                                              style={customStyle}
                                              className="basic-single"
                                              classNamePrefix="select"
                                              isSearchable={isSearchable}
                                              // isClearable={isClearable}
                                              menuIsOpen={openProject}
                                              placeholder={'Select Project..'}
                                              options={projectList
                                                .filter(id => id.isSelectedProject == false)
                                                .filter(c => c.pTeamId === selectedCard.tmTeamId)}
                                              value={projectList.find(
                                                id => id.value == (project ? project.value : selectedCard.ProjectId),
                                              )}
                                              onChange={chooseProjectHandler}
                                              // onBlur={false}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {selectedTeam && selectedTeam.IsAdmin ? (
                                            <Button
                                              variant="contained"
                                              size="small"
                                              onClick={changePage}
                                              style={{ fontWeight: 400 }}>
                                              create Project
                                            </Button>
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-9 mb-3 teams">
                          <Select
                            // styles={customDropdownStyles}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            menuIsOpen={openProject}
                            placeholder={'Select Project..'}
                            options={projectList
                              .filter(id => id.isSelectedProject == false)
                              .filter(c => c.pTeamId === selectedCard.tmTeamId)}
                            value={projectList.find(id => id.value == (project ? project.value : selectedCard.ProjectId))}
                            onChange={chooseProjectHandler}
                          />
                        </div> */}

                          <div className="col-md-3 mb-3 d-flex align-items-center">
                            <label className="cardDetailsLabel">Priority</label>
                          </div>
                          <div className="col-md-9 mb-3 teams">
                            <div className="">
                              <div className="project-add">
                                <PriorityListDropdown
                                  currentPriority={selectedCard.PriorityId}
                                  priorityChangeHandler={priorityChangeHandler}
                                  cardData={selectedCard}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </div>

              <Card sx={{ minWidth: 275 }} style={{ margin: '-2.5rem 1rem 1rem', boxShadow: 'none' }}>
                <CardContent component="div">
                  <div className="row">
                    <div className="col-md-12 mb-3 ">
                      {showFocus ? (
                        <DescriptionInput
                          getTaskContributors={getTaskContributors}
                          kanbanBoard={kanbanBoard}
                          tmTeamId={TeamId}
                          tmTaskId={selectedCard.id}
                          editorRef={descRef}
                          name="TaskDescription"
                          placeholder="Description"
                          setMentionCount={setMentionCount}
                          multiline
                          fullWidth
                          getCorrectedHTML={getCorrectedHTML}
                          // value={quillInputValue}
                          value={taskDescription}
                          // onBlur={handleDescBlurEditor}
                          onChange={handleDescChangeEditor}
                          handleDescKeyDown={handleDescKeyDown}
                          addNotification={addNotification}
                          variant="outlined"
                          size="100%"
                          height={200}
                          selectedCard={selectedCard}
                          // members={mentionDescMembers}
                          id={selectedCard.id}
                          members={mentionTeamMembers}
                          isEdtingDescription={isEdtingDescription}
                          handleDescBlurEditor={handleDescBlurEditor}
                        />
                      ) : null}
                      {/* <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        mention={{
                          separator: ' ',
                          trigger: '@',
                          suggestions: mentionTeamMembers,
                        }}
                        hashtag={{}}
                        placeholder="Add Comments"
                        editorState={editorStates}
                        onEditorStateChange={onEditorStateChanges}
                        onContentStateChange={handleContentStateChanges}
                        toolbar={toolbarOptions}
                        ref={editorRef}
                        // defaultEditorState={taskDescription}
                      /> */}
                      {/* <DescriptionCommentBox editorRef={descRef} /> */}
                      <Button
                        variant="contained"
                        size="small"
                        style={{ position: 'absolute', top: 5, left: '88%', textTransform: 'none' }}
                        onClick={handleDescBlurEditor}
                        // onClick={handleDescBlurEditors}
                      >
                        Save
                      </Button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <Box className="dropzone-card">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Typography variant={'body1'}>Drag 'n' drop some files here, or click to select files</Typography>
                        </div>
                      </Box>

                      {/* <Typography variant={"h4"}>Files</Typography> */}
                      <List disablePadding sx={{ display: 'flex' }}>
                        <div className="attachmentConatiner">{thumbs}</div>
                        {getActivities.length > 0 &&
                          getActivities.map((file, idx) => (
                            <div>
                              <div key={file.id} style={thumb}>
                                <div style={thumbInner}>
                                  {file.metaData.type.split('/')[1] === 'xlsx' ||
                                  file.metaData.type.split('/')[1] === 'xls' ||
                                  file.metaData.type ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                  file.metaData.type === 'application/vnd.ms-excel' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/3642/PNG/512/excel_filetype_icon_227888.png"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'png' ||
                                    file.metaData.type.split('/')[1] === 'jpeg' ||
                                    file.metaData.type.split('/')[1] === 'jpg' ||
                                    file.metaData.type.split('/')[1] === 'gif' ? (
                                    <a href={file.fullImage} download>
                                      <img src={file.fullImage} style={img} alt="" />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'pdf' ? (
                                    <a href={file.fullImage} download>
                                      <PictureAsPdfIcon style={{ width: 40, height: 29, color: 'red' }} />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'csv' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnUns9-S3b5O6Ky3kb5nAq0yp3HDmU3s-JB6RsstWsXsJiGt_DKoZ5Jsp0dvSu7y7tqpE&usqp=CAU"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : // <a href={file.fullImage} download><InsertDriveFileIcon style={{ width: 40, height: 29, color: 'green' }} /></a>
                                  file.metaData.type.split('/')[1] === 'doc' ||
                                    file.metaData.type.split('/')[1] === 'docx' ||
                                    file.metaData.type ===
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                    file.metaData.type === 'application/msword' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/2397/PNG/512/microsoft_office_word_logo_icon_145724.png"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'pptx' ||
                                    file.metaData.type.split('/')[1] === 'ppt' ||
                                    file.metaData.type ===
                                      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/292/PNG/512/PPT_30914.png"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'txt' || file.metaData.type === 'text/plain' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/3642/PNG/512/txt_filetype_icon_227883.png"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'odt' ||
                                    file.metaData.type === 'application/vnd.oasis.opendocument.text' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/2753/PNG/512/ext_odt_filetype_icon_176243.png"
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : file.metaData.type.split('/')[1] === 'movie' ||
                                    file.metaData.type.split('/')[1] === 'mp4' ||
                                    file.metaData.type.split('/')[1] === 'ogv' ||
                                    file.metaData.type.split('/')[1] === 'webm' ||
                                    file.metaData.type.split('/')[1] === 'mpeg' ||
                                    file.metaData.type.split('/')[1] === 'wmv' ||
                                    file.metaData.type.split('/')[1] === 'avi' ||
                                    file.metaData.type.split('/')[1] === 'f4v' ||
                                    file.metaData.type.split('/')[1] === 'flv' ||
                                    file.metaData.type === 'video/x-sgi-movie' ||
                                    file.metaData.type === 'video/mp4' ||
                                    file.metaData.type === 'video/ogg' ||
                                    file.metaData.type === 'video/webm' ||
                                    file.metaData.type === 'video/x-ms-wmv' ||
                                    file.metaData.type === 'video/x-msvideo' ||
                                    file.metaData.type === 'video/x-f4v' ||
                                    file.metaData.type === 'video/x-flv' ||
                                    file.metaData.type === 'video/mpeg' ? (
                                    <a href={file.fullImage} download>
                                      <img
                                        src="https://cdn.icon-icons.com/icons2/1147/PNG/512/1486486316-arrow-film-movie-play-player-start-video_81236.png                                          "
                                        style={{ width: 40, height: 29 }}
                                      />
                                    </a>
                                  ) : (
                                    undefined
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        {getActivities.length > 0 && <LightboxGallery data={getActivities} />}
                      </List>
                      <Card
                        sx={{ minWidth: 275 }}
                        style={{
                          margin: '1rem',
                          // position: 'fixed',
                          bottom: 0,
                          borderTop: 1,
                          width: '100%',
                          margin: 0,
                          boxShadow: 'none',
                          position: 'relative',
                          overflow: 'scroll',
                        }}>
                        <CardContent component="div">
                          <div className="row p-0 relative">
                            <div className="col-md-12 p-0 relative">
                              <Divider variant="middle" />

                              <CommentsInput
                                handleDescBlurEditor={handleDescBlurEditor}
                                commentRef={commentRef}
                                selectedCard={selectedCard}
                                addNotification={addNotification}
                                tmTeamId={TeamId}
                                tmTaskId={selectedCard.id}
                                name="CommentsInputDescription"
                                placeholder="Description"
                                multiline
                                fullWidth
                                setMentionCount={setMentionCount}
                                value={comments}
                                readOnly={!showFocus}
                                // onBlur={handleDescBlurEditor}
                                onChange={handleCommentEditor}
                                // onChange={handleComChangeEdit
                                size="100%"
                                height={60}
                                id={TeamId}
                                members={mentionTeamMembers}
                                modules={{
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                }}
                              />
                              {/* <div className="task_Comments_Edter">
                                <Editor
                                  // ref={commentRef}
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  mention={{
                                    separator: ' ',
                                    trigger: '@',
                                    suggestions: mentionTeamMembers,
                                  }}
                                  style={{ height: '200px' }}
                                  hashtag={{}}
                                  placeholder="Add Comments"
                                  editorState={editorState}
                                  onEditorStateChange={handleCommentEditorStateChange}
                                  onContentStateChange={handleCommentsContentStateChange}
                                  toolbar={toolbarOptions}
                                />
                              </div> */}

                              {/* <EditorWithMentionHashtag members={mentionTeamMembers} /> */}

                              <Button
                                variant="contained"
                                size="small"
                                style={{ position: 'absolute', top: 5, left: '88%', textTransform: 'none' }}
                                onClick={addCommentHandler}
                                // onClick={addCommentHandlerdata}
                              >
                                Comment
                              </Button>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <CommentDisplay
                commentsData={commentsData}
                selectedCard={selectedCard}
                kanbanBoard={kanbanBoard}
                TeamId={TeamId}
                projectList={projectList}
                members={members}
              />
            </div>
          )}
        </Drawer>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={Modalestyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: 'center' }}>
              Are you sure delete{' '}
              <span style={{ color: '12px', fontWeight: 'bold' }}>{deleteAttachment?.tAttachmentName}</span> {''} this
              Attachment
            </Typography>
            <div className="deleteModalButon">
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteAttachmentFile}>
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openEditModal}
          onClose={edithandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={Modalestyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: 'center' }}>
              This File {''}
              <span style={{ color: '12px', fontWeight: 'bold' }}>{attachmentName}</span> {''} Already Exists,Do you want
              replace it?
            </Typography>
            <div className="deleteModalButon">
              <Button variant="outlined" onClick={edithandleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={EditAttachmentFile}>
                Replace
              </Button>
            </div>
          </Box>
        </Modal>
      </React.Fragment>
    </PageContainer>
  );
};

export default TaskDrawer;

import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography, Popover, AvatarGroup, Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
// import { fontSize } from '@mui/system';
// import { Cursor } from '@amcharts/amcharts4/charts';
const ListViewStyles = makeStyles(theme => ({
  root: {
    background: 'white',

    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingRight: '8px',
      paddingLeft: '8px',
      '& .MuiListItemAvatar-root': {
        minWidth: '40px',
      },
      '& .MuiAvatar-root': {
        width: '30px',
        height: '30px',
        fontSize: '0.95rem',
      },
    },
  },
}));

Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

const TeamDataListView = ({ SelectedTeamMembers, avatarClickHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarIndex, setAvatarIndex] = useState(0);

  useEffect(() => {
    let isActive = false;
    let currIndex = 0;
    SelectedTeamMembers.map((item, index) => {
      if (item.ClassName === 'avatar-selected') {
        currIndex = index;
        isActive = true;
      }
    });

    if (isActive) {
      setAvatarIndex(currIndex);
    } else {
      setAvatarIndex(0);
    }
    // console.log(SelectedTeamMembers);
  }, [SelectedTeamMembers]);

  const handleClick = event => {
    // console.log('on avatar click')
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function stringAvatar(obj) {
    let children = '';
    if (obj?.AvatarImage) {
      children = (
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={`data:image/jpeg;base64,${obj.AvatarImage}`}
        />
      );
    } else {
      let name = obj?.Email;
      children = `${name?.charAt(0)}${name?.charAt(1)}`;
    }
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: children,
    };
  }
  const classes = ListViewStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const setFilter = avatar => {
    avatarClickHandler(avatar);
    handleClose();
  };
  const getUpdateOrder = ar => {
    if (ar?.length <= 4) return ar;
    // let newArr  = ar.splice(0, 0, ar.splice(avatarIndex, 1)[0])
    let newArr = [...ar];
    if (avatarIndex) {
      let curr = [...ar];
      curr.splice(0, 0, curr?.splice(avatarIndex, 1)[0]);
      newArr = [...curr];
    }

    return newArr;
  };

  const avatarClickAction = (e, args, index) => {
    if (SelectedTeamMembers?.length <= 4) {
      avatarClickHandler(args);
    } else {
      handleClick(e);
    }
    // handleClick(e);
  };

  return (
    <div>
      {SelectedTeamMembers && (
        <AvatarGroup style={{ cursor: 'pointer' }} max={4} onClick={SelectedTeamMembers.length > 4 ? handleClick : null}>
          {getUpdateOrder(SelectedTeamMembers).map((avatar, index) => (
            <Tooltip key={index} title={avatar?.Email} arrow>
              <Avatar
                key={index}
                {...stringAvatar(avatar)}
                onClick={
                  SelectedTeamMembers?.length > 4
                    ? null
                    : e => {
                        avatarClickAction(e, avatar, index);
                      }
                }
                className={avatar.ClassName}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      )}
      <div className={classes.root}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            background: 'transperent',
          }}
          sx={{ borderRadius: '7px', top: '5px' }}>
          <List
            sx={{
              padding: 0,
              cursor: 'pointer',
            }}>
            {SelectedTeamMembers &&
              SelectedTeamMembers?.map((avatar, index) => {
                return (
                  <ListItem
                    key={index}
                    divider={true}
                    onClick={() => setFilter(avatar)}
                    style={{ display: 'flex', flexDirection: 'row', padding: '4px 8px ' }}>
                    <ListItemAvatar sx={{ minWidth: '40px' }}>
                      <Avatar
                        key={index}
                        {...stringAvatar(avatar)}
                        // onClick={() => avatarClickHandler(avatar)}
                        className={avatar.ClassName}
                        sx={{ width: 30, height: 30, fontSize: '0.95rem' }}
                      />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography color="text.primary" sx={{ fontSize: '0.75rem', fontFamily: 'normal' }}>
                        {avatar?.Email}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Popover>
      </div>
    </div>
  );
};

export default TeamDataListView;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@material-ui/core/Button';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../../microsoft-context/AppContext';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

/* Material Tables */

import { lighten, makeStyles } from '@material-ui/core/styles';
import { FETCH_ERROR, FETCH_START } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('nitin@dscission.com', 'Connected', 6.0, 24, 4.0),
//   //   createData('vedant@dscission.com', 'disconnected', 9.0, 37, 4.3),
// ];

export default function MailboxesTable() {
  const [userProfile, setUserProfile] = React.useState({});
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const app = useAppContext();
  const getUserProfile = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('/Account/UserInfo', { headers: authHeader() })
        .then(({ data }) => {
          setLoader(false);
          setUserProfile(data);
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div>
      {' '}
      {/* Wrapping the table and heading in a div */}
      <h3 className="mb-3">Connected Mailboxes</h3> {/* Main heading */}
      <TableContainer sx={{ width: 850 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'normal' }}>Mailbox Name</TableCell>
              <TableCell sx={{ fontFamily: 'normal' }} align="left">
                Status
              </TableCell>
              <TableCell sx={{ fontFamily: 'normal' }} align="left">
                Sign in /Sign out
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ fontFamily: 'normal' }} component="th" scope="row">
                {!loader ? (
                  userProfile.Email
                ) : (
                  <div className={classes.root}>
                    <CircularProgress />
                  </div>
                )}
              </TableCell>
              <TableCell sx={{ fontFamily: 'normal' }} align="left">
                {app.user ? 'Connected' : 'Disconnected'}
              </TableCell>
              <TableCell sx={{ fontFamily: 'normal' }} align="left">
                <AuthenticatedTemplate>
                  <div>
                    <p>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'none' }}
                        onClick={() => app.signOut()}>
                        Sign out
                      </Button>
                    </p>
                  </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={() => app.signIn()}>
                    Sign in
                  </Button>
                </UnauthenticatedTemplate>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

/* eslint-disable no-unused-expressions */
/**
 * Version 1.0.2
 * Vikas W
 * 13-08-2022
 * -
 * "Task Management"
 */
import React, { useState, useEffect, useCallback, useRef, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, makeStyles, Tooltip, useTheme } from '@material-ui/core';
// import { alpha } from '@material-ui/core/styles';
import Board from 'react-trello';
import { Card } from './Card';

import { kanbanBoard } from '../../../../routes/Pages/Planning/TaskmanagementPage/components/kanbanBoard';
import TaskDrawer from './CardDetail/TaskDrawer';

import AddNewCard from './AddNewCard';
import { setProjectList, setSinglArchive } from '../../../../redux/actions/Common';
import ListHeader from './ListHeader';

import AddCardButton from './AddCardButton';
import NewListForm from './NewListForm';
import NewListButton from './NewListButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* Sidebar drawer */
import { Box, FormControl, Icon, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment';
import Select from 'react-select';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { usePollTask } from './TaskToolbarComponent.js/PollTask';
import { CSVLink } from 'react-csv';
import { getStore } from '../../../../redux/store';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import {
  setTmTeamMembers,
  setTmTaskByTeam,
  setTmStatusByTeam,
  setTmCardData,
  setTmCardDateFlag,
  setTmSelectedTeam,
  setBordeListData,
  setChecked,
} from '../../../../redux/actions';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import ImportExcel from './ImportExcel';

/* Date Picker */
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import $, { event } from 'jquery';
import TeamDataListView from './TaskToolbarComponent.js/TeamDataListView';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TaskFilterDialog from './TaskToolbarComponent.js/TaskFilterDialog';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { CustomContentLoader } from './Contentloader';
import { NavLink } from 'react-router-dom';
import QuickFilter from './TaskToolbarComponent.js/QuickFilter';
import { last } from 'lodash';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';

import TimeManagementModal from './TimeManagementModal';
import CloneModal from './CloneModal';
import ConfirmationModal from './ConfirmationModal';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const menuStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px ',
      '& div': {
        marginRight: '8px',
        fontSize: '0.95rem',
      },
    },
    top: '40px !important',
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
}));

/* START: Search Icon */
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
/* End: Search Icon */

const useTabStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Normal',

    // backgroundColor: theme.palette.background.paper,
  },
  tabBody: {
    padding: 0,

    '& > div': {
      padding: 0,
      marginTop: 10,
    },
  },
  tabFontFam: {
    div: {
      fontFamily: 'normal',
    },
    span: { fontFamily: 'normal' },
  },
  tabsRow: {
    display: 'flex',
    '& .MuiCardContent-root': {
      width: '100%',
    },
  },
  tabsRowPlus: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '17px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    float: 'right',
    marginTop: '-35px',
    marginRight: '25px',
    position: 'relative',
  },
  badges: {
    marginTop: '10px',
  },
  noTabs: {
    marginTop: '5px',
    fontSize: '1rem',
    padding: '5px 20px',
  },
}));

const TaskmanagementPage = () => {
  /* START: Modal Card Tasks */
  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => {
    setOpenModal(false);
    dispatch(setTmCardDateFlag([]));
  };

  /* User Info */
  const { authUser } = useSelector(({ auth }) => auth);
  const [userDetails, setUserDetails] = useState();
  //console.log(authUser);
  // console.log(authUser, 'authUser');

  /* START: Date picker for Tasks */
  const [dueDateValue, setDueDateValue] = React.useState(new Date());
  const [checkedCard, setCheckedCard] = React.useState(false);

  /* START:  Import excel for task */
  const [modal, setModal] = useState(false);

  /* START:  Select Team member for kanban board data change */
  const [Teams, setTeams] = useState([]);
  const [avatarSelected, setAvatarSelected] = useState(null);
  const [TeamMembers, setTeamMembers] = useState([]);
  const [SelectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [teamWiseTabs, setTeamWiseTabs] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [project, setProject] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  /* END:  Select Team member for kanban board data change */

  const dispatch = useDispatch();
  const store = getStore().getState();

  const { tmTaskByTeam } = useSelector(({ common }) => common);
  const { tmStatusByTeam } = useSelector(({ common }) => common);
  const { tmCardData } = useSelector(state => state.common);
  const { archiveBoardData } = useSelector(state => state.common);
  const { tmDatePickerPlag } = useSelector(state => state.common);
  const { singleArchive } = useSelector(state => state.common);
  const { multiArchive } = useSelector(state => state.common);
  const { cardCheck } = useSelector(state => state.common);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const user = kanbanBoard.members[0];
  const classes = menuStyles();
  const theme = useTheme();
  const [boardData, setBoardData] = useState(kanbanBoard.boards);
  const [boardBkData, setBoardBkData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedLaneId, setSelectedLaneId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const downloadCsv = useRef(null);
  const [csvFilterData, setCsvFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [updateName, setUpdateName] = useState([]);
  const boradRef = useRef();
  const inputRef = useRef(null);
  const tabClasses = useTabStyles();
  const [connection, setConnection] = useState(null);
  const descRef = useRef(null);
  const commentRef = useRef(null);
  const [filterMembers, setFilterMembers] = useState([]);
  const [sockettoken, setSocketToken] = useState();

  //time modal
  const [openTimeModal, setOpenTimeModa] = useState(false);
  const [openTimeModaData, setOpenTimeModalData] = useState(null);

  //clone data
  const [cloneData, setCloneData] = useState({});
  const [openCloneModal, setOpenCloneModal] = useState(false);

  //confirmation modal
  const defaultConfirmationModalObj = {
    isModalOpen: false,
    handleSave: null,
    handleClose: null,
    modalTitle: '',
    modalMainText: '',
    hideCloseBtn: false,
    cancelBtnTxt: null,
    saveBtnText: null,
  };

  const [confirmationData, setConfirmationData] = useState({ ...defaultConfirmationModalObj });

  const toggleTimeModal = () => {
    setOpenTimeModa(prev => !prev);
  };

  function socketauthToken() {
    const token = JSON.parse(localStorage.getItem('sockettoken'));
    if (sockettoken && sockettoken?.data) {
      return sockettoken.data;
    } else {
      dispatch({ type: FETCH_START });
      customAxios.post('/Task/GenerateWebsocketToken', null, { headers: authHeader() }).then(data => {
        // localStorage.setItem('sockettoken', JSON.stringify(data));
        setSocketToken(data);
        dispatch({ type: FETCH_SUCCESS });
      });
    }
  }

  useEffect(() => {
    socketauthToken();
  }, []);

  useEffect(() => {}, []);
  const token = JSON.parse(localStorage.getItem('sockettoken'));
  // const URL_WEB_SOCKET = `wss://api.focus247.online/api/Task/TaskWebSocket', ${['token=' + token?.data]}`;
  const URL_WEB_SOCKET = 'wss://api.focus247.online/api/Task/TaskWebSocket?Token=' + sockettoken?.data;
  const [ws, setWs] = useState(null);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    if (Object.keys(singleArchive).length > 0) {
      handleCardArchive(singleArchive?.TaskId, singleArchive?.laneId);
    }
  }, [singleArchive]);

  useEffect(() => {
    if (Object.keys(multiArchive).length > 0) {
      handleCardMultiArchive(multiArchive?.card, multiArchive?.laneId);
    }
  }, [multiArchive]);

  /* Import excel modal open/close */
  const toggle = () => {
    setModal(!modal);
  };

  const downloadCsvHandle = () => {
    handleClickData();
  };

  const actionsList = [
    {
      onClick: toggle,
      label: <IntlMessages id="crm.excelCSVImport" />,
    },
    {
      onClick: downloadCsvHandle,
      label: <IntlMessages id="crm.excelCSVExport" />,
    },
  ];

  const handleClickData = () => {
    downloadCsv.current.link.click();
  };

  /* START:  Select Team member for kanban board data change */
  const onTeamChangeHandler = args => {
    setLoading(false);
    setSelectedTeam(args);
    setAvatarSelected(null);
    dispatch(setTmSelectedTeam(args));
    kanbanBoard.teamIds = args;
    const teamWise = tabs.filter(obj => obj?.CrmTeamId === args?.value);
    /* Change Team data based on top left Team dropdown selected and change updated drag and drop order */
    const teamW = teamWise.map((obj, index) => {
      var temp = Object?.assign({}, obj);
      if (temp?.CrmTeamId == args?.value) {
        temp.value = `${index + 1}`;
        return temp;
      }
    });
    setTeamWiseTabs(teamW);
    if (args?.value) {
      getTeamMembers(args);
    }
  };

  const getTeams = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('TaskTeams/GetTaskTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            var team = data.map(c => ({ value: c.Id, label: c.tmTeamName, IsAdmin: c.IsAdmin, TMPrefix: c.TaskRefPrefix }));

            if (team) {
              setTeams(team);
              setSelectedTeam(team[0]);
              dispatch(setTmSelectedTeam(team[0]));
              kanbanBoard.teamIds = team[0];
              setTimeout(() => {
                getTeamMembers(team[0]);
              }, 1000);
            }
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getUserProfileDetails = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('Users/GetUserProfile', { headers: authHeader() })
        .then(({ data }) => {
          let correctedData = data;

          setUserDetails(correctedData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getTeamMembers = args => {
    customAxios.get('TaskTeams/GetTaskTeamMembers/' + args?.value, { headers: authHeader() }).then(({ data }) => {
      if (data) {
        const members = data.filter(obj => obj.TeamId === args?.value);
        // setSelectedTeamMembers([...members,{}]);
        const modifyMemberNone =
          members &&
          members.map(mem => {
            return { ...mem, ClassName: 'avatar-none' };
          });
        setSelectedTeamMembers([
          {
            ClassName: 'avatar-none',
            Email: 'not assigned',
            IsAdmin: '',
            TMTeamName: '',
            TMUserId: '',
            TeamId: '',
          },
          ...modifyMemberNone,
        ]);
        setTeamMembers(data);
        kanbanBoard.tmTeams = members;

        //for add filter data to check task
        var users = data.map(c => ({
          value: c.TMUserId,
          label: c?.Email,
          isSelectedMenber: c.TMUserId,
        }));
        setFilterMembers(users);
        //storing team members data into react redux state
        dispatch(setTmTeamMembers(data));
      }
    });
  };

  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${name.charAt(0)}${name.charAt(1)}`,
    };
  }
  /* END:  Select Team member for kanban board data change */

  /* START: Notification API */
  const addNotification = (notifyObj, emailObj) => {
    dispatch({ type: FETCH_START });
    // console.log(TeamMembers);
    // console.log(notifyObj);
    let toEmail = [];
    if (TeamMembers) {
      TeamMembers.forEach(member => {
        if (member.TMUserId == notifyObj.UserIdTo) {
          toEmail.push(member?.Email);
        }
      });
    }
    customAxios
      .post('/Notifications/AddNotification', notifyObj, { headers: authHeader() })
      .then(data => {
        if (data) {
          if (toEmail.length > 0) {
            // console.log('email sent');
            // console.log(emailObj);
            InitiateNotificationEmail(toEmail, notifyObj.NotifyMessage, '', [], [], emailObj);
          }
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  /* END: Notification API */

  /* START : Email Notification API */
  const checkArrAuth = item => {
    if (item) {
      return item.length > 0 ? item : [];
    }
    return [];
  };
  const getEmailBody = (messageObj, latestUpdate) => {
    let bodyParms = {
      mainHeading: '',
      madeBy: '',
      madeOn: '',
      mainMessage: '',
      taskTitle: '',
      taskDescription: '',
    };
    let taskStatus = tmStatusByTeam.filter(item => item.id == messageObj.cardData?.StatusId);
    let isComment = false;
    let refNo = selectedTeam?.TMPrefix + ':' + messageObj.cardData.tRefNumber;
    switch (messageObj.type) {
      case 'dueDate':
        bodyParms.mainHeading = `Due Date Changed on Task ` + refNo;
        bodyParms.madeBy = messageObj.doneBy;
        bodyParms.madeOn = new Date().toISOString();
        bodyParms.mainMessage = messageObj.message + ' to ' + latestUpdate.tDueDate.slice(0, 10);
        break;
      case 'assigni':
        bodyParms.mainHeading = `You are Assigned Task ` + refNo;
        bodyParms.madeBy = messageObj.doneBy;
        bodyParms.madeOn = new Date().toISOString();
        bodyParms.mainMessage = 'Task ' + refNo + ' assigned to ' + latestUpdate.TaskAssignedToDisplayName;
        break;
      case 'comment':
        bodyParms.mainHeading = `New Comment on Task ` + refNo;
        bodyParms.madeBy = messageObj.doneBy;
        bodyParms.madeOn = new Date().toISOString();
        bodyParms.mainMessage = messageObj.message;
        isComment = true;
        break;
      case 'taskContributors':
        bodyParms.mainHeading = `You are Added as Task Contributor: Task ${refNo}`;
        bodyParms.madeBy = messageObj.doneBy;
        bodyParms.madeOn = new Date().toISOString();
        bodyParms.mainMessage = `You have been added as a task contributor for Task ${refNo}.`;
        break;
      default:
        bodyParms.mainHeading = `New Update on Task ` + refNo;
        bodyParms.madeBy = messageObj.doneBy;
        bodyParms.madeOn = new Date().toISOString();
        bodyParms.mainMessage = messageObj.message;
    }

    let emailBody = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml" lang="en">
  
        <head><link rel="stylesheet" type="text/css" hs-webfonts="true" href="https://fonts.googleapis.com/css?family=Lato|Lato:i,b,bi">
          <title>Email template</title>
          <meta property="og:title" content="Email template">
          
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
          
          <style type="text/css">
         
            a{ 
              text-decoration: underline;
              color: inherit;
              font-weight: bold;
              color: #253342;
            }
            
            h1 {
              font-size: 56px;
            }
            
              h2{
              font-size: 28px;
              font-weight: 900; 
            }
            
            p {
              font-weight: 100;
            }
            img{
              max-width:450px
            }
            
            td {
          vertical-align: top;
            }
            
            #email {
              margin: auto;
              width: 700px;
              background-color: white;
              box-shadow:5px 5px rgba(0,0,0,0.100)
            }
            
            button{
              font: inherit;
              background-color: #FF7A59;
              border: none;
              padding: 10px;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-weight: 900; 
              color: white;
              border-radius: 5px; 
              box-shadow: 3px 3px #d94c53;
            }
            
            .subtle-link {
              font-size: 9px; 
              text-transform:uppercase; 
              letter-spacing: 1px;
              color: #CBD6E2;
            }
            .prop{
              font-weight: 600;
              font-size:1rem;
              width:fit-content;
              flex:none;

            }
            .val{
              font-weight: 500;
              font-size:1.1rem;
            }
            .rowCtn{
              display:flex;
              justify-content:start;
              align-items:baseline;
              margin-bottom:15px !important; 
            }
           
            
          </style>
          
        </head>
          
          <body bgcolor="#F5F8FA" style="width: 100%; margin: auto 0; padding:0; font-family:Lato, sans-serif; font-size:18px; color:#33475B; word-break:break-word">
        
       <! View in Browser Link --> 
            
      <div id="email">
            
        
        
               <table role="presentation" width="100%">
                  <tr>
               
                    <td bgcolor="#00A4BD" align="center" style="color: white;">
                  
                   <!-- <img alt="Flower" src="https://hs-8886753.f.hubspotemail.net/hs/hsstatic/TemplateAssets/static-1.60/img/hs_default_template_images/email_dnd_template_images/ThankYou-Flower.png" width="400px" align="middle"> -->
                      
                      <h1> Focus247 </h1>
                      
                      
                    </td>
              </table>
        
        
        
        
        
        <table role="presentation" border="0" cellpadding="0" cellspacing="10px" style="padding: 30px 30px 30px 60px;">
           <tr>
          <td>
          ${
            isComment
              ? `
                 <h2> ${bodyParms.mainHeading}, Status: ${taskStatus[0].title} </h2>
                <div>
                 <div class='rowCtn' >  <span class='prop'>Comment By : &nbsp; </span> <span class='val'>${
                   latestUpdate.TaskCommentsUserDisplayName
                 }</span></div>
                 </div>
                 <div>
                 <div class='rowCtn' >  <span class='prop'>Comment Date : &nbsp; </span> <span class='val'>${bodyParms.madeOn.slice(
                   0,
                   10,
                 )}</span></div>
                </div>

                 <div>
                   <div class='rowCtn' >  <span class='prop'>Comment : &nbsp; </span> <span class='val'>${
                     latestUpdate.tComments
                   }</span></div>
                   
                 </div>
                
               `
              : `
                 <h2> ${bodyParms.mainHeading}, Status: ${taskStatus[0].title} </h2>
                 <div>
                 <div class='rowCtn' >  <span class='prop'>Updated By : &nbsp; </span> <span class='val'>${
                   bodyParms.madeBy
                 }</span></div>
                 </div>
                 <div>
                 <div class='rowCtn' >  <span class='prop'>Updated On  : &nbsp; </span> <span class='val'>${bodyParms.madeOn.slice(
                   0,
                   10,
                 )}</span></div>
                </div>
                 <div>
                   <div class='rowCtn' >  <span class='prop' >Update Message : &nbsp; </span> <span class='val'>  ${
                     bodyParms.mainMessage
                   } </span></div>
                 </div>
                 
               `
          }
           <div>
                   <div class='rowCtn' >  <span class='prop'>Task Name  :  &nbsp; </span> <span class='val'>${
                     messageObj.cardData.TaskName
                   }</span></div>
                   
                 </div>
                 <div>
                   <div class='rowCtn' >  <span class='prop'>Task Description  : &nbsp; </span> <span class='val'>${
                     latestUpdate.TaskDescription
                   }</span></div>
                 </div>
                 <div>
               
                   <div class='rowCtn' >  <span class='prop'>Task Due-Date  : &nbsp; </span> <span class='val'>${
                     latestUpdate.tDueDate ? latestUpdate.tDueDate.slice(0, 10) : ''
                   }</span></div>
                  
                 </div>
                
                 <div>
                   <div class='rowCtn' >  <span class='prop'>Task Attachments  : &nbsp; </span> <span class='val'>${
                     latestUpdate.tAttachmentName ? latestUpdate.tAttachmentName : ''
                   }</span></div>
                  
                 </div>
           </td>
                </tr>
                       </table>
        
       
            </div>
          </body>
            </html>`;
    // console.log(bodyParms);
    return emailBody;
  };
  const InitiateNotificationEmail = (toEmail, NotiMessage, subject, ccEmail, bccEmail, NotificationObject) => {
    let emailObj = {
      EmailSubject: NotiMessage,
      EmailPlainTextBody: '',
      EmailHtmlBody: '',
      ToList: checkArrAuth(toEmail),
      CcList: checkArrAuth(ccEmail),
      BccList: checkArrAuth(bccEmail),
    };
    //get task latest updates
    let url = 'Task/GetEmailAPITaskUpdates/' + NotificationObject?.cardData?.id;
    try {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          emailObj.EmailHtmlBody = getEmailBody(NotificationObject, data[data.length - 1]);
          sendNotificationEmail(emailObj);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {}

    // return;
  };

  const sendNotificationEmail = notifyObj => {
    try {
      customAxios

        .post('Invoice/SendEmail', notifyObj, { headers: authHeader() })
        .then(({ data }) => {})
        .catch(error => {
          console.log(error);
        });
    } catch (e) {}
  };
  /* END: Email Notification API */

  /* Check Redux Card Data */
  const taskD = tasks?.filter(t => t?.id == tmCardData[0]?.PrimaryKeyValue);

  // const noteObj = {
  //   UserIdTo: tmCardData[0]?.NewValue,
  //   ActivityId: tmCardData[0]?.PrimaryKeyValue,
  //   NotifyMessage: `Task  ${selectedTeam?.TMPrefix}: ${taskD[0]?.tRefNumber}  Assigned to you!`,
  //   FunctionalityId: tmCardData[0]?.StatusId,
  //   TeamId: tmCardData[0]?.TeamId,
  //   IsClicked: 0,
  // };
  // const emailObj = {
  //   type: 'assigni',
  //   cardData: taskD[0],
  //   doneBy: authUser?.Email,
  //   message: `Task  ${selectedTeam?.TMPrefix}: ${taskD[0]?.tRefNumber}  Assigned to you!`,
  // };

  // console.log({ noteObj, emailObj, tasks, tmCardData, authUser });

  useEffect(() => {
    if (tmCardData?.length > 0) {
      const taskD = tasks.filter(t => t.id == tmCardData[0].PrimaryKeyValue);
      // console.log(tmCardData);
      // console.log(taskD);
      let message = `Task  ${selectedTeam?.TMPrefix}: ${taskD[0].tRefNumber}  Assigned to you!`;
      const noteObj = {
        UserIdTo: tmCardData[0].NewValue,
        ActivityId: tmCardData[0].PrimaryKeyValue,
        NotifyMessage: message,
        FunctionalityId: tmCardData[0]?.StatusId,
        TeamId: tmCardData[0].TeamId,
        IsClicked: 0,
      };
      const emailObj = {
        type: 'assigni',
        cardData: taskD[0],
        doneBy: authUser?.Email,
        message: message,
      };
      addNotification(noteObj, emailObj);

      if (avatarSelected) {
        /* Change/Filter cards based on selected avatar */
        let final = [];
        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == tmCardData[0]?.StatusId);
          let cardIndex =
            newState.lanes[laneIndex] &&
            newState.lanes[laneIndex].cards?.findIndex(data => data.id == tmCardData[0].PrimaryKeyValue);
          if (tmCardData[0].ColumnName === 'AssignedTo') {
            newState.lanes[laneIndex].cards[cardIndex]['AssignedTo'] = tmCardData[0].NewValue;
          }
          if (tmCardData[0].ColumnName === 'tDueDate') {
            newState.lanes[laneIndex].cards[cardIndex]['tDueDate'] = tmCardData[0].NewValue;
            newState.lanes[laneIndex].cards[cardIndex]['dueDate'] = tmCardData[0].NewValue;
          }
          newState = reArrangeCardAccordingToPriority({ ...newState });
          let oldState = { ...newState };

          let updatedState = { ...newState };

          updatedState.lanes.forEach(lane => {
            let data = {
              ...lane,
              cards: lane.cards.filter(card => card.AssignedTo == avatarSelected.TMUserId),
            };
            final.push(data);
          });
          updatedState.lanes = final;
          let data = { ...updatedState };
          setBoardData(reArrangeCardAccordingToPriority(data));
          return oldState;
        });
      } else {
        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == tmCardData[0].StatusId);
          let cardIndex =
            newState.lanes[laneIndex] &&
            newState.lanes[laneIndex].cards?.findIndex(data => data.id == tmCardData[0].PrimaryKeyValue);

          if (tmCardData[0].ColumnName === 'AssignedTo') {
            newState.lanes[laneIndex].cards[cardIndex]['AssignedTo'] = tmCardData[0].NewValue;
          }
          if (tmCardData[0].ColumnName === 'tDueDate') {
            newState.lanes[laneIndex].cards[cardIndex]['tDueDate'] = tmCardData[0].NewValue;
            newState.lanes[laneIndex].cards[cardIndex]['dueDate'] = tmCardData[0].NewValue;
          }
          if (tmCardData[0].ColumnName === 'ProjectId') {
            newState.lanes[laneIndex].cards[cardIndex]['ProjectId'] = tmCardData[0].NewValue;
          }

          let data = { ...newState };
          return reArrangeCardAccordingToPriority(data);
        });
      }

      dispatch(setTmCardData([]));
    }

    if (tmDatePickerPlag.length > 0) {
      setOpenModal(true);
      setDueDateValue(tmDatePickerPlag[0].tDueDate ? new Date(tmDatePickerPlag[0].tDueDate) : new Date());
    }
  }, [tmCardData, tmDatePickerPlag]);

  /* Change/Filter cards with checked and unchecked */

  useEffect(() => {
    if (cardCheck?.length > 0) {
      if (avatarSelected) {
        /* Change/Filter cards based on selected avatar */
        let final = [];
        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == cardCheck[0]?.StatusId);
          let cardIndex =
            newState.lanes[laneIndex] &&
            newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardCheck[0]?.PrimaryKeyValue);
          if (cardCheck[0].ColumnName === 'IsCompleted') {
            newState.lanes[laneIndex].cards[cardIndex]['IsCompleted'] = cardCheck[0]?.NewValue;
          }
          let oldState = reArrangeCardAccordingToPriority({ ...newState });

          let updatedState = { ...newState };
          updatedState.lanes.forEach(lane => {
            let data = {
              ...lane,
              cards: lane.cards.filter(card => card.AssignedTo == avatarSelected.TMUserId),
            };
            final.push(data);
          });
          updatedState.lanes = final;
          let data = reArrangeCardAccordingToPriority({ ...updatedState });
          setBoardData(data);
          return oldState;
        });
      } else {
        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == cardCheck[0].StatusId);
          let cardIndex =
            newState.lanes[laneIndex] &&
            newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardCheck[0].PrimaryKeyValue);

          if (cardCheck[0].ColumnName === 'IsCompleted') {
            newState.lanes[laneIndex].cards[cardIndex]['IsCompleted'] = cardCheck[0].NewValue;
          }
          let data = reArrangeCardAccordingToPriority({ ...newState });
          return data;
        });
      }

      dispatch(setChecked([]));
    }
  }, [cardCheck]);

  useEffect(() => {
    getUserProfileDetails();
    setBoardData({
      lanes: [
        {
          // currentPage: 1,
          id: 'board-tasks',
          title: 'Tasks',
          cards: [],
        },
      ],
    });
    kanbanBoard.boards.lanes = [];
    getTeams();
  }, []);

  useEffect(() => {
    if (Teams?.length > 0) {
      const getTeamMembersByTeam = async obj => {
        const responses = [];
        for (let i = 0; i < Teams?.length; i++) {
          let url = 'Project/GetProjects/' + Teams[i]?.value;
          responses.push(await customAxios.get(url, { headers: authHeader() }));
        }
        if (responses) {
          const teamProject = [];
          for (let i = 0; i < responses.length; i++) {
            responses[i].data.forEach(element => {
              teamProject.push(element);
            });
          }
          setProject(teamProject.filter(element => element?.ArchiveFlag === 0));
          dispatch(setProjectList(teamProject));
        }
      };
      getTeamMembersByTeam();
    }
    return () => {
      setProject([]);
    };
  }, [Teams]);

  useEffect(() => {
    if (selectedTeam) {
      getStatus(selectedTeam.value);
    }
  }, [selectedTeam]);
  const UpdateTask = args => {
    if (args) getStatus(args.value);
  };

  usePollTask(UpdateTask, 30000, selectedTeam);

  function getStatus(id) {
    // console.log/('stausEnter');
    if (!id) {
      return;
    }
    if (id) {
      try {
        kanbanBoard.boards.lanes = [];
        // dispatch({ type: FETCH_START });
        customAxios
          .get('/Task/GetStatus/' + id, { headers: authHeader() })
          .then(({ data, status }) => {
            if (data.length == 0) {
              setBoardData(kanbanBoard.boards);
              setLoading(true);
            }
            // dispatch({ type: FETCH_SUCCESS });
            data.forEach((element, index) => {
              let newStatus = {
                // currentPage: index + 1,
                id: element.Id.toString(),
                title: element.StatusName,
                cards: [],
              };
              kanbanBoard.boards.lanes.push(newStatus);
            });

            if (kanbanBoard.boards.lanes.length > 0) {
              /* Store all status by team in redux */
              dispatch(setTmStatusByTeam(kanbanBoard.boards.lanes));
              getTasks(id);
            }
          })
          .catch(function(error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
          });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
      kanbanBoard.boards.lanes.shift();
    }
  }

  function hasSearchedData(cardData, newSearchKey) {
    // console.log(cardData);
    let hasData = true;

    if (newSearchKey != '') {
      hasData = cardData?.TaskName.toLowerCase().includes(newSearchKey) || cardData.tRefNumber == newSearchKey;
      // HtmlDataExtractor(cardData.description).includes(newSearchKey);
    }
    return hasData;
  }

  function timeManagementHandler(bodyEdit, dispatchObj, setIsCompletCheckBox) {
    setOpenTimeModalData({ bodyEdit, dispatchObj, setIsCompletCheckBox });
    setOpenTimeModa(true);
  }

  //handel Task Actions

  function handelCardActions(cardData, actiontype) {
    try {
      if (actiontype === 'CloneTask') {
        setOpenCloneModal(true);
        setCloneData(cardData);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  function getTasks(id) {
    // console.log('enter1')
    if (!id) return;
    try {
      // dispatch({ type: FETCH_START });
      customAxios
        .get('/Task/GetTasks/' + id, { headers: authHeader() })
        .then(({ data, status }) => {
          setLoading(true);
          const tskObj = data.map(task => ({
            id: task.tId,
            laneId: task.StatusId,
            StatusId: task.StatusId,
            TaskName: task.TaskName,
            TaskDescription: task.TaskDescription,
            tDueDate: task.tDueDate,
            title: task.TaskName,
            description: task.TaskDescription,
            memberIds: task.AssignedTo,
            dueDate: task.tDueDate,
            AssignedTo: task.AssignedTo,
            tmTeamId: task.tmTeamId,
            IsCompleted: task.IsCompleted,
            tRefNumber: task.tRefNumber,
            ProjectId: task.ProjectId,
            tCreated: task.DateCreated,
            timeManagementHandler: timeManagementHandler,
            handelCardActions: handelCardActions,
            PriorityId: task.PriorityId,
          }));
          setTasks(tskObj);
          kanbanBoard.tmTasks = tskObj;
          //storing team members data into react redux state
          dispatch(setTmTaskByTeam(tskObj));

          kanbanBoard.boards.lanes.forEach(element => {
            data.forEach(task => {
              if (task.StatusId == element.id) {
                element.cards.push({
                  id: task.tId,
                  laneId: task.StatusId,
                  StatusId: task.StatusId,
                  TaskName: task.TaskName,
                  TaskDescription: task.TaskDescription,
                  tDueDate: task.tDueDate,
                  title: task.TaskName,
                  description: task.TaskDescription,
                  memberIds: task.AssignedTo,
                  dueDate: task.tDueDate,
                  AssignedTo: task.AssignedTo,
                  tmTeamId: task.tmTeamId,
                  IsCompleted: task.IsCompleted,
                  tRefNumber: task.tRefNumber,
                  ProjectId: task.ProjectId,
                  timeManagementHandler: timeManagementHandler,
                  handelCardActions: handelCardActions,
                  PriorityId: task.PriorityId,
                });
              }
            });
          });
          const bkData = reArrangeCardAccordingToPriority(kanbanBoard.boards);
          // console.log(bkData)
          setBoardBkData(bkData);
          if (avatarSelected) {
            let final = [];
            let newState = { ...bkData };
            newState.lanes.forEach(lane => {
              let data = {
                ...lane,
                cards: lane.cards.filter(card => {
                  return card.AssignedTo == avatarSelected.TMUserId && hasSearchedData(card, searchKey);
                }),
              };
              final.push(data);
            });
            newState.lanes = final;
            let data = reArrangeCardAccordingToPriority({ ...newState });
            setBoardData(data);
          } else {
            if (searchKey) {
              let data = getFilteredData(kanbanBoard.boards);
              setBoardData(reArrangeCardAccordingToPriority(data));
            } else {
              setBoardData(reArrangeCardAccordingToPriority(kanbanBoard.boards));
            }
          }
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  // console.log(tasks, 'idtask');

  function getFilteredData(bkData) {
    let final = [];
    let newState = { ...bkData };
    newState.lanes.forEach(lane => {
      let data = {
        ...lane,
        cards: lane.cards.filter(card => {
          return hasSearchedData(card, searchKey);
        }),
      };
      final.push(data);
    });
    newState.lanes = final;
    let data = { ...newState };
    return data;
  }
  /* START: Search bar */

  const searchTmData = (event, currData) => {
    // console.log(boardData);

    let newSearchKey = event.toString().toLowerCase();
    setSearchKey(newSearchKey);
    let oldState = currData ? { ...currData } : { ...boardBkData };
    let final = [];
    oldState.lanes.forEach(lane => {
      let data = {
        ...lane,
        cards: lane.cards.filter(card => {
          if (avatarSelected && !currData) {
            return hasSearchedData(card, newSearchKey) && card.AssignedTo == avatarSelected.TMUserId;
          } else {
            return hasSearchedData(card, newSearchKey);
          }
        }),
      };
      final.push(data);
    });
    oldState.lanes = final;
    let data = reArrangeCardAccordingToPriority({ ...oldState });
    setBoardData(data);
  };
  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    if (avatarSelected) {
      searchTmData('');
    } else {
      setBoardData(boardBkData);
    }
  };
  /* END: Search bar */

  const shouldReceiveNewData = nextData => {
    setBoardData(nextData);
  };

  const handleCardDelete = (cardId, laneId) => {
    // handleSocketCardDelete(cardId, laneId);
    // delete
    const deleteObj = {
      tmTeamId: selectedTeam.value,
      TaskId: cardId,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/DeleteTask', deleteObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          // newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          let data = { ...newState };
          return data;
        });
        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          let data = { ...newState };
          return data;
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleCardArchive = (cardId, laneId) => {
    const archiveObj = {
      tmTeamId: selectedTeam.value,
      TaskId: cardId,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/ArchiveTask', archiveObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          // newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          let data = { ...newState };
          return data;
        });
        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          let data = { ...newState };
          return data;
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleCardMultiArchive = (cardId, laneId) => {
    let archiveObj = [];
    cardId.map(item => {
      archiveObj = [
        ...archiveObj,
        {
          tmTeamId: item?.tmTeamId,
          TaskId: item?.id,
        },
      ];
    });
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/ArchiveMultipleTasks', archiveObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          archiveObj.map((card, index) => {
            let cardIndex =
              newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == card?.TaskId);
            newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          });
          let data = { ...newState };
          return data;
        });
        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          archiveObj.map((card, index) => {
            let cardIndex =
              newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == card?.TaskId);
            newState.lanes[laneIndex].cards.splice(cardIndex, 1);
          });
          let data = { ...newState };
          return data;
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleCardAdd = (card, laneId) => {
    // console.info('handleCardAdd', card, laneId);
    let addTaskObj = {
      tmTeamId: selectedTeam.value,
      ClientId: null,
      tName: `${card.title}`,
      StatusId: card.laneId,
      TaskDescription: `${card.description}`,
      AssignedToId: card.assignTo ? card.assignTo : avatarSelected ? avatarSelected.TMUserId : null,
      TaskDueDate: card?.dueDate || null,
    };
    // console.log(addTaskObj);
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/AddTask', addTaskObj, { headers: authHeader() })
      .then(data => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          getStatus(selectedTeam.value);
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleListAdd = list => {
    // eslint-disable-next-line no-console
    // console.info('new Lane', list);
  };
  const handleOnLaneDelete = laneId => {
    // eslint-disable-next-line no-console

    let delObj = {
      TmTeamId: selectedTeam.value,
      StatusId: parseInt(laneId),
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/DeleteStatus', delObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  const handleOnLaneUpdate = (laneId, data) => {
    // eslint-disable-next-line no-console
    // console.info('new update lane', laneId, data);
    let editObj = {
      StatusId: laneId,
      tmTeamId: selectedTeam.value,
      tmStatusName: `${data.title}`,
      tmStatusDesc: `${data.title}`,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditStatus', editObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const getLaneById = id => boardData.lanes.find(item => item.id === id);

  const getCardById = (lane, cardId) => lane?.cards?.find(item => item.id === cardId);
  const onCardClick = (cardId, metadata, laneId) => {
    // console.log(cardId, metadata, laneId);
    const lane = getLaneById(laneId);
    const card = getCardById(lane, cardId);
    setSelectedCard(card);
    setSelectedLaneId(laneId);
    setOpenDrawer(!openDrawer);
  };

  const handleDragCard = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    setTimeout(() => {
      if (sourceLaneId !== targetLaneId) {
        const sourceLane = getLaneById(sourceLaneId);
        const targetLane = getLaneById(targetLaneId);
        const updatedCard = {
          ...cardDetails,
        };
        const bodyEditStatus = {
          ColumnName: 'StatusId',
          PrimaryKeyValue: `${cardDetails.id}`,
          NewValue: `${targetLaneId}`,
          TableName: 'TM_Tasks',
          TeamId: selectedTeam.value,
        };
        dispatch({ type: FETCH_START });
        customAxios
          .post('/Task/EditTask', bodyEditStatus, { headers: authHeader() })
          .then(data => {
            dispatch({ type: FETCH_SUCCESS });
          })
          .catch(function(error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
          });

        setBoardBkData(prevState => {
          let newState = { ...prevState };
          let sourceLaneIndex = newState.lanes?.findIndex(data => data.id === sourceLaneId);
          let targetLaneIndex = newState.lanes?.findIndex(data => data.id === targetLaneId);
          let cardSourceIndex =
            newState?.lanes[sourceLaneIndex] &&
            newState?.lanes[sourceLaneIndex]?.cards?.findIndex(data => {
              return data.id == cardDetails?.id;
            });

          if (cardSourceIndex === -1) {
            let data = { ...newState };
            return reArrangeCardAccordingToPriority(data);
          } else {
            newState.lanes[sourceLaneIndex].cards[cardSourceIndex]['StatusId'] = parseInt(targetLaneId);
            newState.lanes[sourceLaneIndex].cards[cardSourceIndex]['laneId'] = targetLaneId.toString();
            newState.lanes[targetLaneIndex].cards.push(newState.lanes[sourceLaneIndex].cards[cardSourceIndex]);
            if (newState.lanes[sourceLaneIndex].cards.splice(cardSourceIndex, 1)) {
              let data = reArrangeCardAccordingToPriority({ ...newState });
              return data;
            }
          }
        });

        // setBoardData(prevState => {
        //   let newState = { ...prevState };
        //   let sourceLaneIndex = newState.lanes?.findIndex(data => data.id === sourceLaneId);
        //   let targetLaneIndex = newState.lanes?.findIndex(data => data.id === targetLaneId);
        //   let cardIndex =
        //     newState?.lanes[targetLaneIndex] &&
        //     newState?.lanes[targetLaneIndex]?.cards?.findIndex(data => {
        //       return data.id == cardDetails.id;
        //     });

        //   newState.lanes[targetLaneIndex].cards[cardIndex]['StatusId'] = parseInt(targetLaneId);
        //   newState.lanes[targetLaneIndex].cards[cardIndex]['laneId'] = targetLaneId.toString();
        //   newState.lanes[targetLaneIndex].cards.push(newState.lanes[targetLaneIndex].cards[cardIndex]);
        //   newState.lanes[targetLaneIndex].cards.splice(cardIndex, 1);
        //   let data = { ...newState };
        //   return data;
        // });
        setBoardData(prevState => {
          let newState = { ...prevState };
          let sourceLaneIndex = newState.lanes?.findIndex(data => data.id == sourceLaneId);
          let targetLaneIndex = newState.lanes?.findIndex(data => data.id == targetLaneId);
          let cardIndex =
            newState.lanes[targetLaneIndex] &&
            newState.lanes[targetLaneIndex].cards?.findIndex(data => data.id == cardDetails.id);
          newState.lanes[targetLaneIndex].cards[cardIndex]['StatusId'] = parseInt(targetLaneId);
          newState.lanes[targetLaneIndex].cards[cardIndex]['laneId'] = targetLaneId.toString();
          let data = reArrangeCardAccordingToPriority({ ...newState });
          return data;
        });
      }
    }, 500);
  };

  const onCardUpdate = (updatedCard, laneId) => {
    const lane = getLaneById(laneId);
    const updatedLane = {
      ...lane,
      cards: lane.cards.map(item => (item.id === updatedCard.id ? updatedCard : item)),
    };
    const updatedBoard = {
      ...boardData,
      lanes: boardData.lanes.map(item => (item.id === updatedLane.id ? updatedLane : item)),
    };
    setBoardData(updatedBoard);
  };

  /* Sidebar Drawer */
  const toggleDrawer = () => event => {
    setOpenDrawer(!openDrawer);
  };

  /* START: Task Details Screen */
  const handleDueDateChange = (dateChange, cardId) => {
    const taskD = tasks.filter(t => t.id == cardId);
    let laneId = taskD[0].laneId;
    let message = `${authUser?.Email} has changed the due date for task  ${selectedTeam?.TMPrefix}:${taskD[0].tRefNumber}`;
    const noteObj = {
      UserIdTo: taskD[0].AssignedTo,
      ActivityId: taskD[0].id,
      NotifyMessage: message,
      FunctionalityId: taskD[0]?.StatusId,
      TeamId: taskD[0].tmTeamId,
      IsClicked: 0,
    };
    const emailObj = {
      type: 'dueDate',
      cardData: taskD[0],
      doneBy: authUser?.Email,
      message: message,
    };
    addNotification(noteObj, emailObj);
    // console.log(taskD);

    /* checking updated status(lane) and task from sidebar  */
    let newState = { ...boardData };
    let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
    let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);

    let changeDate = dateChange ? moment(dateChange).format('YYYY-MM-DD') : null;

    const changeObj = {
      ColumnName: 'tDueDate',
      PrimaryKeyValue: `${cardId}`,
      NewValue: changeDate,
      TableName: 'TM_Tasks',
      TeamId: selectedTeam.value,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', changeObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        newState.lanes[laneIndex].cards[cardIndex].dueDate = changeDate;
        newState.lanes[laneIndex].cards[cardIndex].tDueDate = changeDate;
        setBoardData(newState);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleUpdateDescriptionInBoard = (newData, cardId) => {
    const taskD = tasks.filter(t => t.id == cardId);
    let laneId = taskD[0].laneId;
    setBoardData(prevState => {
      let newState = { ...prevState };
      let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
      let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
      if (cardIndex === -1) {
        let data = { ...newState };
        return data;
      } else {
        newState.lanes[laneIndex].cards[cardIndex]['TaskDescription'] = newData;
        newState.lanes[laneIndex].cards[cardIndex]['description'] = newData;
        let data = { ...newState };
        return data;
      }
    });
  };

  const onInputChangeHandler = (event, args) => {
    let InputVal = event.target.value;
    let key = event.target.name;
    let cardId = args;

    const taskD = tasks.filter(t => t.id == cardId);
    let laneId = taskD[0].laneId;

    for (let keyR in taskD[0]) {
      if (keyR === key && taskD[0][keyR] !== InputVal.replace(/\s+/g, ' ').trim()) {
        const newVal = InputVal.replace(/\s+/g, ' ').trim();
        const changeObj = {
          ColumnName: key,
          PrimaryKeyValue: `${cardId}`,
          NewValue: `${newVal}`,
          TableName: 'TM_Tasks',
          TeamId: selectedTeam.value,
        };

        dispatch({ type: FETCH_START });
        customAxios
          .post('/Task/EditTask', changeObj, { headers: authHeader() })
          .then(data => {
            dispatch({ type: FETCH_SUCCESS });
            if (key === 'TaskName') {
              if (laneId) {
                setBoardData(prevState => {
                  let newState = { ...prevState };
                  let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
                  let cardIndex =
                    newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
                  if (cardIndex === -1) {
                    let data = { ...newState };
                    return data;
                  } else {
                    newState.lanes[laneIndex].cards[cardIndex]['title'] = InputVal.replace(/\s+/g, ' ').trim();
                    newState.lanes[laneIndex].cards[cardIndex]['TaskName'] = InputVal.replace(/\s+/g, ' ').trim();
                    let data = { ...newState };
                    return data;
                  }
                });
              }
            }
          })
          .catch(function(error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
          });
      }
    }
  };
  /* END: Task Details Screen */

  /* START: Task Dreawer and individual task card screens */
  const inputTeamChangeHandler = (args, cardId, TeamId) => {
    /* checking updated status(lane) and task from sidebar  */
    const taskD = tasks.filter(t => t.id == cardId);
    let message = `Task  ${selectedTeam?.TMPrefix}: ${taskD[0].tRefNumber}  Assigned to you!`;
    const noteObj = {
      UserIdTo: args.value,
      ActivityId: taskD[0].id,
      NotifyMessage: message,
      FunctionalityId: taskD[0]?.StatusId,
      TeamId: taskD[0].tmTeamId,
      IsClicked: 0,
    };
    const emailObj = {
      type: 'assigni',
      cardData: taskD[0],
      doneBy: authUser?.Email,
      message: message,
    };
    addNotification(noteObj, emailObj);

    let laneId = taskD[0].laneId;

    let teamObj = {
      ColumnName: 'AssignedTo',
      PrimaryKeyValue: cardId,
      NewValue: args.value,
      TableName: 'TM_Tasks',
      TeamId: TeamId,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', teamObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        if (avatarSelected) {
          //   /* Change/Filter cards based on selected avatar */
          let newState = { ...boardBkData };
          // let laneIndex = newState.lanes?.findIndex(res => res.id == laneId);

          // let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(res => res.id == cardId);

          // if (cardIndex == -1) {
          //   //  console.log(boardBkData)

          //   let newBoardData = boardBkData;

          //   let tempIndex = newBoardData.lanes[laneIndex].cards?.findIndex(res => res.id == cardId);
          //   //  console.log(cardId)
          //   //  console.log(tempIndex)
          //   newBoardData.lanes[laneIndex].cards[tempIndex]['AssignedTo'] = args.value;
          //   newState.lanes[laneIndex].cards.push(newBoardData.lanes[laneIndex].cards[tempIndex]);
          //   // let newState = { ...boardBkData };
          // } else {
          //   newState.lanes[laneIndex].cards[cardIndex]['AssignedTo'] = args.value;
          // }

          // let data = { ...newState };
          let oldState = { ...newState };

          let updatedState = { ...newState };
          let final = [];

          updatedState.lanes.forEach(lane => {
            let data = {
              ...lane,
              cards: lane.cards.filter(card => {
                if (card.id == cardId) {
                  card['AssignedTo'] = args.value;
                }

                return card.AssignedTo == avatarSelected.TMUserId && (searchKey ? card.TaskName == searchKey : true);
              }),
            };
            final.push(data);
          });
          updatedState.lanes = final;
          let data = { ...updatedState };

          setBoardData(data);
        } else {
          setBoardData(prevState => {
            let newState = { ...prevState };
            let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
            let cardIndex =
              newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
            newState.lanes[laneIndex].cards[cardIndex]['AssignedTo'] = args.value;

            let data = { ...newState };
            if (searchKey) {
              let updatedState = { ...newState };
              let final = [];

              updatedState.lanes.forEach(lane => {
                let data = {
                  ...lane,
                  cards: lane.cards.filter(card => {
                    return card.AssignedTo == avatarSelected.TMUserId && (searchKey ? card.TaskName == searchKey : true);
                  }),
                };
                final.push(data);
              });
              updatedState.lanes = final;
              data = { ...updatedState };
            }

            return data;
          });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /* START: Task Dreawer and individual task card screens */
  const inputProjectChangeHandler = (args, cardId, TeamId) => {
    /* checking updated status(lane) and task from sidebar  */
    const taskD = tasks.filter(t => t.id == cardId);
    let laneId = taskD[0].laneId;
    let teamObj = {
      ColumnName: 'ProjectId',
      PrimaryKeyValue: cardId,
      NewValue: args.value,
      TableName: 'TM_Tasks',
      TeamId: TeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', teamObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          if (cardIndex === -1) {
            let data = { ...newState };
            return data;
          } else {
            newState.lanes[laneIndex].cards[cardIndex]['ProjectId'] = args.value;
            let data = { ...newState };
            return data;
          }
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const priorityChangeHandler = (args, cardId) => {
    /* checking updated status(lane) and task from sidebar  */
    const taskD = tasks.filter(t => t.id == cardId);
    let laneId = taskD[0].laneId;
    let priorityObj = {
      TaskId: cardId,
      PriorityId: args.value,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/UpdateTaskPriority', priorityObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        setBoardData(prevState => {
          let newState = { ...prevState };
          let laneIndex = newState.lanes?.findIndex(data => data.id == laneId);
          let cardIndex = newState.lanes[laneIndex] && newState.lanes[laneIndex].cards?.findIndex(data => data.id == cardId);
          if (cardIndex === -1) {
            let data = { ...newState };
            return reArrangeCardAccordingToPriority(data);
          } else {
            newState.lanes[laneIndex].cards[cardIndex]['PriorityId'] = args.value;
            let data = { ...newState };
            return reArrangeCardAccordingToPriority(data);
          }
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  function reArrangeCardAccordingToPriority(data) {
    // console.log(data);
    data.lanes.forEach(lane => {
      lane.cards.sort((a, b) => {
        // Handle null priority IDs by placing them at the end
        if (a.PriorityId === null && b.PriorityId === null) return 0;
        if (a.PriorityId === null) return 1;
        if (b.PriorityId === null) return -1;

        // Compare PriorityId for ascending order
        return parseInt(a.PriorityId, 10) - parseInt(b.PriorityId, 10);
      });
    });
    return data;
  }
  /* START: Avatar/Team Click Screen */
  const avatarClickHandler = avatar => {
    setBoardData(boardBkData);
    if (avatar.ClassName === 'avatar-selected') {
      setAvatarSelected(null);
      const newTeams = [...SelectedTeamMembers];

      /* updateding all avatar none */
      const newTeams2 = newTeams.map(obj => {
        return { ...obj, ClassName: 'avatar-none' };
      });

      setSelectedTeamMembers(newTeams2);
      if (searchKey) {
        // console.log(searchKey);
        searchTmData(searchKey, boardBkData);
      } else {
        setBoardData(boardBkData);
      }
    } else {
      setAvatarSelected(avatar);
      const newState = [...SelectedTeamMembers];

      /* updateding all avatar none */
      const newState2 = newState.map(obj => {
        return { ...obj, ClassName: 'avatar-none' };
      });

      const index = newState2?.findIndex(m => m.TMUserId == avatar.TMUserId);
      newState2[index]['ClassName'] = 'avatar-selected';

      setSelectedTeamMembers(newState2);
      kanbanBoard.filterTeamSelected = newState2;

      /* Change/Filter cards based on selected avatar */
      let oldState = { ...boardBkData };
      let final = [];
      oldState.lanes.forEach(lane => {
        let data = {
          ...lane,
          cards: lane.cards.filter(card => card.AssignedTo == avatar.TMUserId),
        };
        final.push(data);
      });
      oldState.lanes = final;
      let data = { ...oldState };
      if (searchKey) {
        // console.log(searchKey);
        searchTmData(searchKey, data);
      } else {
        setBoardData(data);
      }
    }
  };

  const avatarClickFilter = (avatar, type) => {
    setBoardData(boardBkData);
    if (type === 'Tasks assigend to me') {
      if (avatar?.ClassName === 'avatar-selected') {
        setAvatarSelected(null);
        const newTeams = [...SelectedTeamMembers];

        /* updateding all avatar none */
        const newTeams2 = newTeams.map(obj => {
          return { ...obj, ClassName: 'avatar-none' };
        });
        setSelectedTeamMembers(newTeams2);
        if (searchKey) {
          // console.log(searchKey);
          searchTmData(searchKey, boardBkData);
        } else {
          setBoardData(boardBkData);
        }
      } else {
        setAvatarSelected(avatar);
        const newState = [...SelectedTeamMembers];

        /* updateding all avatar none */
        const newState2 = newState.map(obj => {
          return { ...obj, ClassName: 'avatar-none' };
        });

        const index = newState2?.findIndex(m => m?.TMUserId == avatar?.TMUserId);
        newState2[index]['ClassName'] = 'avatar-selected';
        setSelectedTeamMembers(newState2);
        kanbanBoard.filterTeamSelected = newState2;

        /* Change/Filter cards based on selected avatar */
        let oldState = { ...boardBkData };
        let final = [];
        oldState.lanes.forEach(lane => {
          let data = {
            ...lane,
            cards: lane.cards.filter(card => card.AssignedTo == avatar.TMUserId),
          };
          final.push(data);
          console.log(data, '<>mdata');
        });
        oldState.lanes = final;
        let data = { ...oldState };
        if (searchKey) {
          // console.log(searchKey);
          searchTmData(searchKey, data);
        } else {
          setBoardData(data);
        }
      }
    } else if (type === 'Incomplete tasks') {
      let oldState = { ...boardBkData };
      let final = [];
      oldState.lanes.forEach(lane => {
        let data = {
          ...lane,
          cards: lane.cards.filter(card => card.IsCompleted === 0 && card.AssignedTo == avatar.TMUserId),
        };
        final.push(data);
      });
      oldState.lanes = final;
      let data = { ...oldState };
      console.log(data, 'data090909');
      if (searchKey) {
        // console.log(searchKey);
        searchTmData(searchKey, data);
      } else {
        setBoardData(data);
      }
    } else if (type === 'Tasks due this week') {
      const today = new Date();
      function getFirstDayOfWeek(d) {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
      }
      const firstDay = getFirstDayOfWeek(today);
      const lastDay = new Date(firstDay);
      lastDay.setDate(lastDay.getDate() + 5);

      const firstweekDay = moment(firstDay).format('YYYY-MM-DD');
      const lastWeekDay = moment(lastDay).format('YYYY-MM-DD');

      let oldState = { ...boardBkData };
      let final = [];
      oldState.lanes.forEach(lane => {
        let data = {
          ...lane,
          cards: lane.cards.filter(card => {
            let filterPass = true;
            const IssueDate = new Date(card.dueDate);
            if (firstweekDay && lastWeekDay) {
              filterPass = filterPass && new Date(firstweekDay) <= IssueDate && new Date(lastWeekDay) >= IssueDate;
            }
            return filterPass;
          }),
        };
        final.push(data);
      });
      oldState.lanes = final;
      let data = { ...oldState };
      if (searchKey) {
        // console.log(searchKey);
        searchTmData(searchKey, data);
      } else {
        setBoardData(data);
      }
    }
  };

  /* END: Avatar/Team Click Screen */

  /* Start advanced filter */
  const advancedClickFilter = avatar => {
    if (avatar) {
      const startDate = moment(avatar?.startDate).format('YYYY-MM-DD');
      const endDate = moment(avatar?.endDate).format('YYYY-MM-DD');
      setBoardData(boardBkData);
      let oldState = { ...boardBkData };
      let final = [];
      oldState.lanes.forEach(lane => {
        let data = {
          ...lane,
          cards: lane.cards.filter(card => {
            if (
              (avatar?.project && avatar.project.some(proj => proj.value === card.ProjectId)) ||
              (avatar?.members && card.AssignedTo === avatar.members.value) ||
              filterDataCheck(startDate, endDate, card)
            ) {
              return card;
            }
          }),
        };
        final.push(data);
      });
      oldState.lanes = final;
      let data = { ...oldState };
      if (searchKey) {
        searchTmData(searchKey, data);
      } else {
        setBoardData(data);
      }
    }
  };

  const filterDataCheck = (startDate, endDate, card) => {
    let filterPass = true;
    const IssueDate = new Date(card.dueDate);
    if (startDate && endDate) {
      filterPass = filterPass && new Date(startDate) <= IssueDate && new Date(endDate) >= IssueDate;
    }
    return filterPass;
  };

  /* START: Card view Date handler */
  const handleDueDateHandler = dateChange => {
    if (tmDatePickerPlag.length > 0) {
      const taskD = tasks.filter(t => t.id == parseInt(tmDatePickerPlag[0].cardId));
      const message = `${authUser?.Email} has changed the due date for task  ${selectedTeam?.TMPrefix}:${taskD[0]?.tRefNumber}`;
      const noteObj = {
        UserIdTo: taskD[0]?.memberIds,
        ActivityId: tmDatePickerPlag[0].cardId,
        NotifyMessage: message,
        FunctionalityId: tmDatePickerPlag[0].status,
        TeamId: tmDatePickerPlag[0].tmTeamId,
        IsClicked: 0,
      };
      const emailObj = {
        type: 'dueDate',
        cardData: taskD[0],
        doneBy: authUser?.Email,
        message: message,
      };

      addNotification(noteObj, emailObj);
      setBoardData(prevState => {
        let newState = { ...prevState };
        let laneIndex = newState.lanes?.findIndex(data => data.id == tmDatePickerPlag[0].id);
        let cardIndex =
          newState.lanes[laneIndex] &&
          newState.lanes[laneIndex].cards?.findIndex(data => data.id == tmDatePickerPlag[0].cardId);
        newState.lanes[laneIndex].cards[cardIndex]['tDueDate'] = dateChange;
        newState.lanes[laneIndex].cards[cardIndex]['dueDate'] = dateChange;
        let data = { ...newState };

        return data;
      });
    }

    let changeDate = moment(dateChange).format('YYYY-MM-DD');
    const changeObj = {
      ColumnName: 'tDueDate',
      PrimaryKeyValue: `${tmDatePickerPlag[0].cardId}`,
      NewValue: `${changeDate}`,
      TableName: 'TM_Tasks',
      TeamId: tmDatePickerPlag[0].tmTeamId,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', changeObj, { headers: authHeader() })
      .then(data => {
        setOpenModal(false);
        dispatch({ type: FETCH_SUCCESS });
        dispatch(setTmCardDateFlag([]));
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const filterCsvData = () => {
    let data = [];
    let newObj = [];
    boardData?.lanes?.map(element => {
      element?.cards?.map(check => {
        data = [...data, check];
      });
    });
    data.map(card => {
      function getText(html) {
        var divContainer = document.createElement('div');
        divContainer.innerHTML = html;
        return divContainer?.textContent || divContainer?.innerText || '';
      }
      var yourString = card?.TaskDescription;
      newObj = [
        ...newObj,
        {
          id: card.id,
          AssignedTo: card?.AssignedTo,
          ProjectId: card?.ProjectId,
          StatusId: card?.StatusId,
          TaskName: card?.TaskName,
          dueDate: card?.dueDate,
          laneId: card?.laneId,
          memberIds: card?.memberIds,
          tDueDate: card?.tDueDate,
          tRefNumber: card?.tRefNumber,
          tmTeamId: card?.tmTeamId,
          TaskDescription: getText(yourString),
          PriorityId: card?.PriorityId,
        },
      ];
    });
    setCsvFilterData(newObj);
  };

  const headers = {
    // Authorization: 'Bearer ' + token.access_token,
    // CustomHeader: 'HEADER_VALUE_HERE',
    Authorization: authHeader(),
  };

  useEffect(() => {
    if (sockettoken && sockettoken.data) {
      const wsClient = new WebSocket(URL_WEB_SOCKET);
      wsClient.onopen = () => {
        setWs(wsClient);
      };

      wsClient.onmessage = evt => {
        const socketData = JSON.stringify(evt?.data);
        const trade = JSON.parse(socketData);
        const newData = trade.split('_');
        console.log(newData);
        const allSocketData = JSON.parse(JSON.stringify(newData[newData?.length - 1]));
        console.log(allSocketData);

        if (newData[1] === 'Deleted') {
          setBoardData(prevState => {
            let newState = { ...prevState };
            let removeIndex = [];
            newState?.lanes?.map(data => {
              let deteteData = data?.cards?.filter(newDatas => newDatas?.id == allSocketData);
              if (deteteData?.length) {
                removeIndex = deteteData;
              }
            });
            let laneIndex = newState?.lanes?.findIndex(data => data?.id == removeIndex[0]?.laneId);
            let cardIndex =
              newState?.lanes[laneIndex] && newState?.lanes[laneIndex]?.cards?.findIndex(data => data?.id == allSocketData);
            newState?.lanes[laneIndex]?.cards?.splice(cardIndex, 1);
            let data = { ...newState };
            return data;
          });
          setBoardBkData(prevState => {
            let newState = { ...prevState };
            let removeIndex = [];
            newState?.lanes?.map(data => {
              let deteteData = data?.cards?.filter(newDatas => newDatas?.id == allSocketData);
              if (deteteData?.length) {
                removeIndex = deteteData;
              }
            });
            let laneIndex = newState?.lanes?.findIndex(data => data?.id == removeIndex[0]?.laneId);
            let cardIndex =
              newState?.lanes[laneIndex] && newState?.lanes[laneIndex]?.cards?.findIndex(data => data?.id == allSocketData);
            newState?.lanes[laneIndex]?.cards?.splice(cardIndex, 1);
            let data = reArrangeCardAccordingToPriority({ ...newState });
            return data;
          });
        }
        if (selectedTeam?.value === allSocketData?.tmTeamId) {
          if (newData[1] === 'Edited') {
            setBoardData(prevState => {
              let newState = { ...prevState };
              let removeIndex = [];
              newState?.lanes?.map(data => {
                let deteteData = data?.cards?.filter(newDatas => newDatas?.id == allSocketData?.tId);
                if (deteteData?.length) {
                  removeIndex = deteteData;
                }
              });
              if (removeIndex.length) {
                if (removeIndex[0]?.StatusId !== allSocketData?.StatusId) {
                  let newState = { ...prevState };
                  let sourceLaneIndex = newState?.lanes?.findIndex(data => data?.id == removeIndex[0]?.StatusId);
                  let targetLaneIndex = newState?.lanes?.findIndex(data => data?.id == allSocketData?.StatusId);
                  let cardSourceIndex =
                    newState?.lanes[sourceLaneIndex] &&
                    newState?.lanes[sourceLaneIndex]?.cards?.findIndex(data => {
                      return data?.id == allSocketData?.tId;
                    });

                  if (cardSourceIndex === -1) {
                    let data = { ...newState };
                    return data;
                  } else {
                    newState.lanes[sourceLaneIndex].cards[cardSourceIndex]['StatusId'] = parseInt(allSocketData?.StatusId);
                    newState.lanes[sourceLaneIndex].cards[cardSourceIndex]['laneId'] = allSocketData?.StatusId?.toString();
                    newState.lanes[targetLaneIndex].cards.push(newState.lanes[sourceLaneIndex].cards[cardSourceIndex]);
                    if (newState.lanes[sourceLaneIndex].cards.splice(cardSourceIndex, 1)) {
                      let data = { ...newState };
                      return data;
                    }
                  }
                } else {
                  let laneIndex = newState?.lanes?.findIndex(data => data?.id == allSocketData?.StatusId);
                  let cardIndex =
                    newState?.lanes[laneIndex] &&
                    newState?.lanes[laneIndex]?.cards?.findIndex(data => data?.id == allSocketData?.tId);

                  newState.lanes[laneIndex].cards[cardIndex]['AssignedTo'] = allSocketData?.AssignedTo;
                  newState.lanes[laneIndex].cards[cardIndex]['memberIds'] = allSocketData?.AssignedTo;
                  newState.lanes[laneIndex].cards[cardIndex]['ClientId'] = allSocketData?.ClientId;
                  newState.lanes[laneIndex].cards[cardIndex]['DateCompleted'] = allSocketData?.DateCompleted;
                  newState.lanes[laneIndex].cards[cardIndex]['IsCompleted'] = allSocketData?.IsCompleted;
                  newState.lanes[laneIndex].cards[cardIndex]['PriorityId'] = allSocketData?.PriorityId;
                  newState.lanes[laneIndex].cards[cardIndex]['ProjectId'] = allSocketData?.ProjectId;
                  newState.lanes[laneIndex].cards[cardIndex]['StatusDescription'] = allSocketData?.StatusDescription;
                  newState.lanes[laneIndex].cards[cardIndex]['TaskDescription'] = allSocketData?.TaskDescription;
                  newState.lanes[laneIndex].cards[cardIndex]['TaskName'] = allSocketData?.TaskName?.replace(
                    /\s+/g,
                    ' ',
                  ).trim();
                  newState.lanes[laneIndex].cards[cardIndex]['tDueDate'] = allSocketData?.tDueDate;
                  newState.lanes[laneIndex].cards[cardIndex]['dueDate'] = allSocketData?.tDueDate;
                  newState.lanes[laneIndex].cards[cardIndex]['tRefNumber'] = allSocketData?.tRefNumber;
                  newState.lanes[laneIndex].cards[cardIndex]['StatusId'] = parseInt(allSocketData?.StatusId);
                  newState.lanes[laneIndex].cards[cardIndex]['laneId'] = allSocketData?.StatusId.toString();
                  newState.lanes[laneIndex].cards[cardIndex]['description'] = allSocketData?.TaskDescription;
                  newState.lanes[laneIndex].cards[cardIndex]['title'] = allSocketData?.TaskName?.replace(/\s+/g, ' ').trim();
                  let data = { ...newState };
                  return data;
                }
              }
            });
          } else if (newData[1] === 'Added') {
            let addnewCard = {
              id: allSocketData?.tId,
              laneId: allSocketData?.StatusId,
              StatusId: allSocketData?.StatusId,
              TaskName: allSocketData?.TaskName,
              TaskDescription: allSocketData?.TaskDescription,
              tDueDate: allSocketData?.tDueDate,
              title: allSocketData?.TaskName,
              description: allSocketData?.TaskDescription,
              memberIds: allSocketData?.AssignedTo,
              dueDate: allSocketData?.tDueDate,
              AssignedTo: allSocketData?.AssignedTo,
              tmTeamId: allSocketData?.tmTeamId,
              IsCompleted: allSocketData?.IsCompleted,
              tRefNumber: allSocketData?.tRefNumber,
              ProjectId: allSocketData?.ProjectId,
              timeManagementHandler: timeManagementHandler,
              handelCardActions: handelCardActions,
              PriorityId: allSocketData?.PriorityId,
            };
            setBoardData(prevState => {
              let newState = { ...prevState };
              let laneIndex = newState?.lanes?.findIndex(data => data?.id == allSocketData?.StatusId);
              let checkCardAdded =
                newState?.lanes[laneIndex].cards &&
                newState?.lanes[laneIndex].cards?.findIndex(addedCard => addedCard?.title === allSocketData?.TaskName);
              if (checkCardAdded === -1) {
                newState?.lanes[laneIndex] && newState?.lanes[laneIndex]?.cards?.push(addnewCard);
                let data = { ...newState };
                return data;
              } else {
                let data = reArrangeCardAccordingToPriority({ ...newState });
                return data;
              }
            });
          }
        }
      };
      return () => {
        if (wsClient.readyState === 0) {
          wsClient.close();
          console.log('close tag');
        }
      };
    }
  }, [selectedTeam]);

  useEffect(() => {
    filterCsvData();
  }, [boardData]);

  // const CardConatiner = props => <Card {...props} handleCardArchive={handleCardArchive} />;
  const ListContainer = props => {
    return <ListHeader {...props} />;
  };

  const components = {
    Card,
    LaneHeader: ListHeader,
    NewLaneForm: NewListForm,
    NewLaneSection: NewListButton,
    AddCardLink: AddCardButton,
    NewCardForm: AddNewCard,
  };
  /* END: Card view Date handler */

  const AddFilter = [
    {
      label: 'Quick filter',
      value: 'Quick filter',
    },
    {
      label: 'Advanced filter',
      value: 'Advanced filter',
    },
  ];

  const onFilterChangeHandler = event => {
    if (event.label === 'Quick filter') {
      setAnchorEl(!anchorEl);
    } else if (event.label === 'Advanced filter') {
      setOpenFilterDialog(!openFilterDialog);
    }
  };

  const [anchorEls, setAnchorEls] = React.useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [mentionCount, setMentionCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloses = () => {
    setAnchorEl(null);
  };
  const [currentValue, setCurrentValue] = useState(0);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function closeConfirmationModal() {
    setConfirmationData(defaultConfirmationModalObj);
  }

  function openteamLeaveModal() {
    let confirmationObj = { ...confirmationData };
    confirmationObj.handleClose = closeConfirmationModal;
    confirmationObj.modalTitle = 'Leave Team';
    if (selectedTeam.IsAdmin) {
      confirmationObj.hideCloseBtn = true;

      confirmationObj.modalMainText = ' You can not leave this team, as you are an admin';
      confirmationObj.saveBtnText = 'Ok';
      confirmationObj.handleSave = closeConfirmationModal;
    } else {
      confirmationObj.hideCloseBtn = false;
      confirmationObj.modalMainText = ' Do you want to leave this team?';
      confirmationObj.saveBtnText = 'Yes';
      confirmationObj.cancelBtnTxt = 'No';
      confirmationObj.handleSave = leaveTeam;
    }

    confirmationObj.isModalOpen = true;
    setConfirmationData(confirmationObj);
  }

  function leaveTeam() {
    let obj = {
      tmTeamId: selectedTeam.value,
      tmMemberUserId: userDetails.UserId,
    };
    customAxios.post('TaskTeams/DeleteTaskTeamMember', obj, { headers: authHeader() }).then(data => {
      closeConfirmationModal();
      setBoardData({
        lanes: [
          {
            // currentPage: 1,
            id: 'board-tasks',
            title: 'Tasks',
            cards: [],
          },
        ],
      });
      kanbanBoard.boards.lanes = [];
      getTeams();
    });
  }

  return (
    <div className="app-wrapper taskManagement" id="tm-wrapper">
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.taskmanagement" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={Teams}
              value={Teams.find(c => c.label == selectedTeam?.label)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
          {selectedTeam && (
            <Button
              onClick={openteamLeaveModal}
              style={{
                marginLeft: '10px',
                backgroundColor: '#3f51b5',
                textTransform: 'capitalize',
              }}
              variant="contained">
              Leave Team
            </Button>
          )}
        </div>
        <div className="content-horizontal__center">
          <Badge badgeContent={currentValue} classes={{ badge: classes.counterRoot }}>
            <Button
              variant="contained"
              className="px-2"
              aria-describedby={id}
              onClick={handleClick}
              style={{
                height: 40,
                minWidth: '25px',
                background: 'none',
                color: '#3f51b5',
              }}>
              <FilterAltIcon />
            </Button>
          </Badge>
          <QuickFilter
            SelectedTeamMembers={SelectedTeamMembers}
            avatarClickFilter={avatarClickFilter}
            // anchorEl={anchorEls}
            setAnchorEl={setAnchorEl}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloses}
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            setCurrentValue={setCurrentValue}
          />
          <TaskFilterDialog
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            filterMembers={filterMembers}
            projectListData={project}
            selectedCard={selectedCard}
            selectedTeam={selectedTeam}
            setFilterData={setFilterData}
            advancedClickFilter={advancedClickFilter}
            onClose={handleCloses}
            setCurrentValue={setCurrentValue}
          />
          {/* <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'filter ..'}
              options={AddFilter}
              // value={''}
              onChange={onFilterChangeHandler}
            />
          </FormControl> */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => searchTmData(e.target.value)}
              value={searchKey}
              inputRef={inputRef}
            />
            {searchKey && (
              <CloseIcon
                onClick={clearSearchData}
                style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
              />
            )}
          </Search>
          <TeamDataListView SelectedTeamMembers={SelectedTeamMembers} avatarClickHandler={avatarClickHandler} />

          {/* <Button variant="contained" color="primary" style={{ height: 40 }} onClick={toggle}>
            <IntlMessages id="crm.excelCSVImport" />
          </Button> */}
          <CmtDropdownMenu
            TriggerComponent={
              <Button
                variant="contained"
                sx={{
                  mx: 2,
                  padding: '6px',
                  minWidth: '40px',
                  backgroundColor: '#3f51b5',
                }}>
                <MoreVertIcon />
              </Button>
            }
            items={[...actionsList]}
            menuClasses={classes.root}
          />
          <CSVLink data={csvFilterData} ref={downloadCsv} filename={'task-file.csv'} />
        </div>
      </div>
      <React.Fragment>
        {Teams.length ? (
          loading ? (
            <Board
              data={boardData}
              // components={{
              //   Card,
              //   LaneHeader: ListHeader,
              //   NewLaneForm: NewListForm,
              //   NewLaneSection: NewListButton,
              //   AddCardLink: AddCardButton,
              //   NewCardForm: AddNewCard,
              // }}
              components={components}
              draggable
              onCardClick={onCardClick}
              onCardDelete={handleCardDelete}
              // onCardMoveAcrossLanes={onCardMoveAcrossLanes}
              onDataChange={shouldReceiveNewData}
              onCardAdd={handleCardAdd}
              editLaneTitle
              editable
              canAddLanes
              onLaneAdd={handleListAdd}
              onLaneDelete={handleOnLaneDelete}
              onLaneUpdate={handleOnLaneUpdate}
              // handleDragStart={handleDragStart}
              handleDragEnd={handleDragCard}
              style={{ backgroundColor: 'transparent', height: '78vh' }}
              laneStyle={{
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                width: 'max(15vw,270px)',
                height: '78vh',
              }}
            />
          ) : (
            <div className="loaderCantainer">
              <CustomContentLoader />
              <CustomContentLoader />
              <CustomContentLoader />
              <CustomContentLoader />
            </div>
          )
        ) : (
          <div className={tabClasses.noTabs}>
            You are not part of any team, Start your own Task Management. Go to "Manage Team" page. Or create your team{' '}
            <NavLink to="/planning/manage-teams">"Here"</NavLink>{' '}
          </div>
        )}
      </React.Fragment>
      {openDrawer && (
        <TaskDrawer
          openDrawer={openDrawer}
          selectedCard={selectedCard}
          toggleDrawer={toggleDrawer}
          onInputChangeHandler={onInputChangeHandler}
          getStatusHandler={getStatus}
          TeamId={selectedTeam.value}
          projectListData={project}
          handleDueDateChange={handleDueDateChange}
          inputTeamChangeHandler={inputTeamChangeHandler}
          inputProjectChangeHandler={inputProjectChangeHandler}
          handleUpdateDescriptionInBoard={handleUpdateDescriptionInBoard}
          addNotification={addNotification}
          descRef={descRef}
          commentRef={commentRef}
          kanbanBoard={kanbanBoard}
          mentionCount={mentionCount}
          setMentionCount={setMentionCount}
          priorityChangeHandler={priorityChangeHandler}
        />
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModal}>
          <Box sx={modalStyle}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CalendarPicker date={dueDateValue} onChange={newDate => handleDueDateHandler(newDate)} />
            </LocalizationProvider>
          </Box>
        </Fade>
      </Modal>

      {modal && (
        <ImportExcel
          modal={modal}
          toggle={toggle}
          selectedTeam={selectedTeam}
          statusByTeam={tmStatusByTeam}
          getStatusHandler={getStatus}
        />
      )}
      <TimeManagementModal
        isModalOpen={openTimeModal}
        setIsModalOpen={setOpenTimeModa}
        openTimeModaData={openTimeModaData}
        allTasks={tasks}
      />
      <CloneModal
        isModalOpen={openCloneModal}
        setModalStatus={setOpenCloneModal}
        cloneTask={() => handleCardAdd(cloneData, null)}
      />

      <ConfirmationModal confirmationObj={confirmationData} />
    </div>
  );
};

export default TaskmanagementPage;
